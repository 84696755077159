.accordion {
  color: #fff;

  &.hideArrows {
    :global(.accordion-button::after) {
      display: none;
    }
  }

   :global(.accordion-button::after) {
    background-image: url(../../../../assets/images/curator/arrow-down.svg);
    background-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-position: center;
    color: #fff;
  }

  :global(.accordion-header) {
  }

  :global(.accordion-button) {
    background: #5a5c61;
    border-radius: 0.5rem !important;
    color: inherit;
    border-color: #5a5c61;
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.5;

    &:not(:global(.collapsed)) {
      background: #2d3136;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-bottom: 1px solid #5a5c61;
    }

    &:hover {
      background: #2d3136;

      .actions {
        opacity: 1;
        pointer-events: initial;
      }
    }

    :global(.form-switch .form-check-input) {
      height: 24px;
      width: 48px;
      margin-bottom: 0;
    }

    :global(.form-check) {
      margin-bottom: 0;
    }
  }

  .checkbox {
    margin-right: 8px;

    input {
      border-radius: 0.5em;
      width: 1.5em;
      height: 1.5em;
      margin-top: 0;
      
    }
  }

  .hasActions :global(.accordion-button):hover .iconContainer {
    display: none;
  }

  .iconContainer {
    margin-left: auto;
  }

  :global(.accordion-item) {
    margin: 1rem 0;
    border: none;
    background-color: transparent;

    &:first-of-type {
      margin-top: 0;
    }

    &.disabled{ 
      pointer-events: none;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top :0;
        bottom: 0;
        border-radius: 8px;
        // background: linear-gradient(180deg, rgba(61, 65, 69, 0) -23.92%, #3D4145 18.78%);
        background-color: #3D4145;
        opacity: 0.6;
        z-index: 3;
      }
    }

    :global(.accordion-body) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  

  :global(.accordion-collapse) {
    background: #2d3136;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .actions {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    padding-right: 12px;
    opacity: 0;
    pointer-events: none;
    transition: all 0s ease;

    .action {
      display: flex;
      height: 100%;
      padding: 0 4px;
      align-items: center;
    }
  }

  .divider {
    border-top: 1px solid #3D4145;
    margin: 1rem -1rem;
  }
}
