.page-nav {
  background-color: var(--header-bg-color);
  text-decoration: none;
  font-size: inherit;
  font-weight: 600;
  font-family: var(--font-family);
  color: var(--secondary-color) !important;
  z-index: 1000;
}

.navbar-text {
  font-size: 0.8em;
  font-weight: 600;
  color: var(--neutral80-color);

  .button {
    text-decoration: none;
    font-size: inherit;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--secondary-color) !important;
  }
}

.common-nav {
  padding-left: 1.5em;
  padding-right: 1.5em;
  box-shadow: 0px 1px 0px var(--menu-shadow-color);

  .navbar-brand {
    margin-right: 1.5rem;
    transform: translateY(-5px);
  }
}

.page-nav.common-nav {
  height: 4rem;
  position: fixed;
  width: 100vw;
}

aside.sidebar {
  height: calc(100vh - 4rem);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 997;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll; 

  &::-webkit-scrollbar {
    display: none;
  }
}

div.page-wrapper {
  padding-left: 18.75rem !important;
  margin-top: 4rem !important;
}


div.full-screeen-page-wrapper {
  padding-left: 0rem !important;
  margin-top: 4rem !important;
}

form.search-form {
  .search-form-group {
    position: relative;
    overflow: hidden;
    // max-width: 300px;

    .search-icon {
      margin: 0;
      padding: 0;
      position: absolute;
      left: 12px;
      top: calc(50% - 8px);
      z-index: 9999;

      img {
        display: block;
      }
    }
  }
  input[type='search'] {
    display: block;
    width: 100%;
    // max-width: 300px;
    padding-left: 2.5em;
    font-size: var(--header-font-size);
    color: var(--neutral80-color);
    font-family: var(--font-family);
    font-weight: 400;
    white-space: nowrap;
    background-color: var(--neutral20-color);
    border: 1px solid var(--neutral20-color);
    border-radius: var(--border-radius);
    height: 40px;
  }
}

.menu {
  li {
    font-size: var(--header-font-size);

    a {
      font-size: inherit;
      color: var(--neutral80-color);
      font-family: var(--font-family);
      font-weight: 400;
      white-space: nowrap;
    }
    a.active {
      color: var(--black-color);
      font-weight: 500;
    }
  }
}
.menu,
button:not(.userSelectionBtn, .btn-grid, .btn-table) {
  img {
    margin: 0;
    margin-right: 0.85em;
    display: inline-block;
    vertical-align: middle;
  }
}

.header-right-section {
  flex: 0 1 calc(300px + 70.4px + 2em);
  max-width: calc(300px + 70.4px + 2em);
  gap: 1em;

  form {
    flex: 0 0 calc(100% - 70.4px - 2em);
    width: calc(100% - 70.4px - 2em);
  }

  .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 0.601em;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--neutral20-color);

    img {
      margin: 0;
      padding: 0;
      max-width: 16px;
    }
  }

  .user-card {
    display: block;
    width: 35.2px;
    height: 35.2px;
    z-index: 100000000;

    .user-card-btn {
      display: block;
      width: 35.2px;
      height: 35.2px;
      margin: 0;
      padding: 0;
      object-fit: cover;
      object-position: center;
      border: none;
      border-radius: var(--border-radius);
      background-color: var(--neutral20-color);
      overflow: hidden;

      > img {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        max-width: 35.2px;
        height: auto;
      }
    }
  }
}

.navbar-brand {
  img {
    width: 120px;
  }
}

.pg-title {
  font-size: var(--pg-title-font);
  color: var(--black-color);
  font-weight: 700;
  font-family: var(--font-family);
  line-height: 1.5;

  span {
    color: var(--neutral80-color);
    font-size: calc(var(--pg-title-font) / 2.85);
    font-weight: 400;
    line-height: 1.5;
    vertical-align: text-bottom;
  }
}

// Wrapper
.wrapper {
  .sidebar {
    display: block;
    position: fixed;
    padding: 1.5em 0 1.5em 1.5em;
    top: 60px;
    left: 0;
    width: var(--sidebar-width);
    height: calc(100% - 60px);
    max-height: 100vh;
    float: left;
    transition: all 0.5s ease;
    background-color: var(--white-color);
    box-shadow: 1px 0px 0px var(--menu-shadow-color);
    z-index: 105;

    .sidebar-wrapper {
      height: 100%;
      overflow-y: auto;
    }

    nav {
      padding-right: 17px;
      max-height: 425px;
      overflow-y: auto;

      .navbar-nav {
        display: block;
        height: 100%;
        position: relative;
      }
    }
  }

  .content {
    width: calc(100vw - 322px);
    height: calc(100vh - 59px);
    //margin-left: calc(var(--sidebar-width) - 0.6rem);
    padding: 1.5rem;
    float: left;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.wrapper {
  .sidebar {
    display: block;
    position: fixed;
    padding: 1.5em 0 1.5em 1.5em;
    top: 60px;
    left: 0;
    width: var(--sidebar-width);
    height: calc(100% - 60px);
    max-height: 100vh;
    float: left;
    transition: all 0.5s ease;
    background-color: var(--white-color);
    box-shadow: 1px 0px 0px var(--menu-shadow-color);
    z-index: 100;

    .sidebar-wrapper {
      height: 100%;
      overflow-y: auto;
    }

    nav {
      padding-right: 17px;
      max-height: 425px;
      overflow-y: auto;

      .navbar-nav {
        display: block;
        height: 100%;
        position: relative;
      }
    }
  }

  .content {
    // width: calc(100% - var(--sidebar-width) - 1px);
    height: calc(100vh - 59px);
    //margin-left: calc(var(--sidebar-width) - 0.6rem);
    padding: 1.5rem 1.5rem 0;
    float: left;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .contentes {
    width: calc(100vw - 300px);
    height: 100vh;
    //margin-left: calc(var(--sidebar-width) - 0.6rem);
    padding: 1.5rem 1.5rem 0;
    float: left;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.body-of-main {
  display: flex;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: gainsboro;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #bebdbd;
    border-radius: 8px;
  }

  .sidebar {
    @include flex-grow();
    background-color: #ffffff;
    box-shadow: 1px 0px 0px #e2e2e2;
    min-width: 300px;
    // overflow-y: scroll;
    height: calc(100vh - 65px);

    header {
      padding: 1em 1em 1em;
      background: #f9f9f9;
      transition: all 0.4s ease-in-out;
      position: relative;

      @media screen and (max-width: 768px) {
        padding: 1em;
      }

      a {
        height: 40px;
        border-radius: 50%;
        text-decoration: none;

        @include d-flex();
        @include d-flex-v-center();

        .menu-label {
          font-size: 16px;
          color: #2d3136;
          font-weight: bold;
          font-size: 16px;
        }

        .downarrow {
          position: absolute;
          right: 6%;
        }
      }
    }
    .menu {
      ul {
        padding: 0;

        li {
          list-style: none;
        }
      }

      a {
        text-decoration: none;
      }

      img {
        border-radius: 8px;
        margin-left: 10px;
        margin-right: 5px;
      }

      .menu-head {
        border-top: 1px solid #e9e9e9;
      }

      .menu-item {
        @include d-flex();
        @include d-flex-v-center();
        padding: 0.8em 2.2em;
        color: $primary-text;
        font-size: 14px;
        font-weight: 500;
        position: relative;

        .more {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #0059f1;
        }

        h6 {
          font-weight: bold;
          font-size: 16px;
        }

        @media screen and (max-width: 768px) {
          padding: 1em;
          @include d-flex-h-center();
        }
      }
    }

    .menu-icon {
      width: 30px;
      text-align: center;
    }

    .menu-label {
      // padding-left: 10px;
      color: #2d3136;

      @media screen and (max-width: 768px) {
        visibility: hidden;
        opacity: 0;
        position: absolute;
      }
    }

    .menu-label-select {
      margin-left: 10px;
    }
  }
  .sidebars {
    @include flex-grow();
    background-color: #ffffff;
    box-shadow: 1px 0px 0px #e2e2e2;
    // min-width: 200px;
    overflow-y: scroll;
    height: 93vh;

    header {
      padding: 1em 1em 1em;
      background: #f9f9f9;
      transition: all 0.4s ease-in-out;
      position: relative;
      @media screen and (max-width: 768px) {
        padding: 1em;
      }

      a {
        height: 40px;
        border-radius: 50%;
        text-decoration: none;

        @include d-flex();
        @include d-flex-v-center();
        .menu-label {
          font-size: 16px;
          color: #2d3136;
          font-weight: bold;
          font-size: 16px;
        }
        .downarrow {
          position: absolute;
          right: 6%;
        }
      }
    }
    .texture-header {
      padding: 1em 1em 1em;
      background-color: #ffffff;
    }
    .menu {
      ul {
        padding: 0;
        li {
          list-style: none;
        }
      }
      a {
        text-decoration: none;
      }
      img {
        border-radius: 8px;
        margin-left: 10px;
        margin-right: 5px;
      }
      .menu-head {
        border-top: 1px solid #e9e9e9;
      }
      .menu-item {
        @include d-flex();
        @include d-flex-v-center();
        padding: 0.8em 2.2em;
        color: $primary-text;
        font-size: 14px;
        font-weight: 500;
        position: relative;

        .more {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #0059f1;
        }

        h6 {
          font-weight: bold;
          font-size: 16px;
        }
        // label {
        //     width: 24px;
        //     height: 24px;
        //     background: #e9e9e9;
        //     border-radius: 8px;
        // }
        // #chk {
        //     background: #e9e9e9;
        //     border-radius: 8px;
        //     border: none;
        //     display: none;
        // }
        // input:checked + label:after {
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     top: 14px;
        //     left: 40px;
        //     width: 7px;
        //     height: 14px;
        //     border: solid #0079bf;
        //     border-width: 0 2px 2px 0;
        //     transform: rotate(45deg);
        // }

        @media screen and (max-width: 768px) {
          padding: 1em;
          @include d-flex-h-center();
        }
      }
    }
    .menu-icon {
      width: 30px;
      text-align: center;
    }
    .menu-label {
      // padding-left: 10px;
      color: #2d3136;

      @media screen and (max-width: 768px) {
        visibility: hidden;
        opacity: 0;
        position: absolute;
      }
    }
    .menu-label-select {
      margin-left: 10px;
    }
    @media screen and (max-width: 768px) {
      max-width: 70px;
    }
  }
  .contents {
    width: calc(100% - var(--sidebar-width) - 65px);
  }
  .sidebars {
    display: block;
    // width: 201px;
    overflow: auto;
    margin-top: 1px;
    height: 100vh;
    transition: all 0.5s ease;
    background-color: var(--white-color);
    box-shadow: 1px 0px 0px var(--menu-shadow-color);
    z-index: 105;

    .sidebar-wrapper {
      height: 100%;
      overflow-y: auto;
    }

    nav {
      padding-right: 17px;
      max-height: 425px;
      overflow-y: auto;

      .navbar-nav {
        display: block;
        height: 100%;
        position: relative;
      }
    }
  }
}

.height_scroll {
  height: 100vh;
  overflow: auto;
}

.fullscreen {
  .container-fluid {
    img {
      height: 100vh;
      object-fit: cover;
    }

    .fullscreen-escbtn {
      button {
        position: absolute;
        width: 228px;
        height: 40px;
        left: calc(50% - 228px / 2);
        top: 90%;
        background: #2d3136;
        border-radius: 8px;
        border: unset;
        color: #ffffff;
      }
    }
  }
}

.wrapper .sidebar {
  display: block;
  position: fixed;
  padding: 1.5em 0 1.5em 1.5em;
  top: 60px;
  left: 0;
  width: var(--sidebar-width);
  height: calc(100% - 60px);
  max-height: 100vh;
  float: left;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: var(--white-color);
  -webkit-box-shadow: 1px 0px 0px var(--menu-shadow-color);
  box-shadow: 1px 0px 0px var(--menu-shadow-color);
  z-index: 100;
}

.wrapper .sidebar .sidebar-wrapper {
  height: 100%;
  overflow-y: auto;
}

.wrapper .sidebar nav {
  padding-right: 17px;
  max-height: 425px;
  overflow-y: auto;
}

.wrapper .sidebar nav .navbar-nav {
  display: block;
  height: 100%;
  position: relative;
}

.wrapper .content {
  width: calc(100vw - 300px);
  height: calc(100vh - 59px);
  /* margin-left: calc(var(--sidebar-width) - 0.6rem); */
  padding: 1.5rem 1.5rem 0;
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
}

.scroll-container {
  min-height: 200px;
}

.scroll-loader {
  height: 80px;
  width: 100%;
}

.container-fluid.grid-section {
  width: calc(100vw - 340px) !important;
}

.wrapper .sidebar {
  display: block;
  position: fixed;
  padding: 1.5em 0 1.5em 1.5em;
  top: 60px;
  left: 0;
  width: var(--sidebar-width);
  height: calc(100% - 60px);
  max-height: 100vh;
  float: left;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: var(--white-color);
  -webkit-box-shadow: 1px 0px 0px var(--menu-shadow-color);
  box-shadow: 1px 0px 0px var(--menu-shadow-color);
  z-index: 105;
}

.wrapper .sidebar .sidebar-wrapper {
  height: 100%;
  overflow-y: auto;
}

.wrapper .sidebar nav {
  padding-right: 17px;
  max-height: 425px;
  overflow-y: auto;
}

.wrapper .sidebar nav .navbar-nav {
  display: block;
  height: 100%;
  position: relative;
}

.wrapper .content {
  width: calc(100vw - 300px);
  height: calc(100vh - 59px);
  /* margin-left: calc(var(--sidebar-width) - 0.6rem); */
  padding: 1.5rem 1.5rem 0;
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
}

.container-padding-top {
  padding-top: 5rem;
}

#whiteShadow {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
  content: '\00a0';
  height: 10%;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

.topMenuNav.show {
  z-index: 2000;
}

.infoIconHelper {
  position: fixed;
  right: 2em;
  bottom: 2em;
  z-index: 4000;
}

.loaderV2 {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;

  &.backdrop {
    background: rgba(255, 255, 255, 0.5);
  }

  .progressContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.circularProgress {
  width: 32px;
  height: 32px;
  border: 4px solid var(--primary-color);
  border-left: 4px solid transparent;
  border-radius: 50%;
  animation: loadingAnimation 0.5s linear 0s infinite;

  @keyframes loadingAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}