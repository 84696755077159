.fovLabel {
  font-size: 0.875rem;
  color: var(--white-color);
}

.fovInput {
  width: 120px;
  position: relative;
  top: -2px;
  margin-left: 1rem;
}

.topMenuItem {
  font-size: 0.875rem;
  min-width: 128px;
  
  :global(.dropdown-toggle) {
    width: 100%;
  }

  :global(.dropdown-item.active), :global(.dropdown-item:hover) {
    background-color: var(--black-color) !important;
    cursor: default;
  }

  :global(.dropdown-menu) {
    left: 50% !important;
    transform: translateX(-50%) !important;
    margin-top:0;

    &:before {
      transform: translateX(-50%);
      left: 50%;
    }
  }
}