.root {
    padding: 0px 16px 0px 24px;
    border-bottom: 1px solid var(--neutral20-color);
    
    
    &.dark {
      border-bottom: 1px solid var(--neutral80-color);
    }

    &:last-of-type {
      border-bottom: none !important;
    }

    
    :global(.form-check) {
      margin-top: 24px;
      margin-bottom: 24px;
      // padding: 12px 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.5;
      display: flex;
      align-items: flex-start;

      &:last-of-type {
        margin-bottom: 16ox;
      }
    }

    :global(.form-check-label) {
      margin-left: 0.5em;
    }

    :global(.form-check-input) {
      flex: 0 0 24px;
      margin-top: 0;
      margin-bottom: 0;
    }

    

    .more {
      margin-bottom: 12px;
      margin-top: 24px;

      span {
        color: var(--secondary-color);
        text-decoration: none;
        margin-bottom: 12px;
        font-weight: normal;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
      }
    }

    h5 {
      margin-top: 12px;
      margin-bottom: 24px;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
    }
  }