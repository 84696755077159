.root {
  position: fixed;
  bottom: 0;
  left: var(--curator-sidebar-width);
  right: 0;
  padding: 28px 44px 24px 24px;
  background: #3D4145;
  border-radius: 8px 8px 0px 0px;
  color: var(--white-color);
  transform: translateY(100%);
  transition: all 0.2s ease;
  min-height: 320px;
  z-index: 6;
  font-size: 0.875rem;
  line-height: 1.7;

  &.visible {
    transform: translateY(0);
  }

  &.fullWidth {
    left: 0;
  }
}

.pagination {
  margin-left: 33px;
}

.closeButton {
  background: var(--neutral80-color);
  border-radius: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
  top: 7px;
  border: none;
  outline: none;
  color: var(--white-color);

  svg {
    width: 10px;
  }
}


.grid {
  display: flex;
  gap: 8.44%;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.7;
}

.column {
  width: 100%;
  
}

.shortcutDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.keyList {
  display: flex;
  align-items: center;

  .key {
    border: 1px solid #5A5C61;
    box-sizing: border-box;
    border-radius: 8px;
    height: 32px;
    min-width: 56px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .separator {
    margin: 0 7px;
  }
}

.highlightedContent {
  border: 1px solid var(--neutral80-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem;
}

.highlightedText {
  display: inline-block;
  width: 48px;

  &.orangeColor {
    color:  #FFA500;
  }

  &.cyanColor {
    color: #41FDFE;
  }
}