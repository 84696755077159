.sticky {
  position: sticky;
  bottom: 0;
  background-color:  #3D4145;
  padding-bottom: 1rem;
  transform: translateY(1rem);
  padding-top: 1rem;
  z-index: 10;
}

.fixed {
  position: fixed;
  left: var(--curator-sidebar-width);
  width: var(--curator-open-sidebar-width) !important;
  bottom: 0;
  padding: 1rem !important; 
  background-color:  #3D4145;
  z-index: 10;
}