.rgbItem {
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}

.colorLineContainer {
  width: 100%;
  position: relative;
  height: 40px;

  input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    position: relative;
    opacity: 0;
  }

  .draggablePointer {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 42px;
    top: -1px;
    left: 0;
    
    pointer-events: none;

    &:before {
      content: '';
      height: 100%;
      width: 8px;
      position: absolute;
      left: 0;
      top: 0;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px 0px 0px 2px;
    }
  }
}

.colorLine {
  position: relative;
  background-color: red;
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px 0px 0px 8px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.colorValue {
  height: 40px;
  background: #3D4145;
  border-radius: 0px 8px 8px 0px;
  flex: 0 0 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  font-size: 1rem;

  input {
    width: 100%;
    border: none;
    outline: none;
    height: 40px;
    font-size: 1rem;
    color: #fff;
    padding-left: 18px;
    background-color: transparent;
  }
}

.letterContainer {
  flex: 0 0 32px;
  font-size: 1.5rem;
  color: var(--neutral40-color);

}