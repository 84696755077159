.container {
  overflow: hidden;
  background: #3D4145;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 35%;
  height: 100%;

  &.containerLoading {
    pointer-events: none;
  }

  .text {
    font-weight: 400;
    font-size: 1.5rem;
    color: var(--neutral80-color);
  }

  .uploadButton {
    height: 60px;
  }
}