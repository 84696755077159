.contentContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.sectionContentContainer {
  padding: 0 0 30px;
  width: 100%;
}

.cardContainer {
  width: 100%;

  .scrollableContent {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  :global(.thumbnail-card) {
    width: calc(calc(100% - 8px) / 2);
  }
}

.searchBarContainer { 
  top: 2.5rem;
}