.root {
  padding-bottom: 56.49%;
  border-radius: 8px;;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.innerImageContainer {
  width: 100%;
  padding-bottom: 100%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
}

.innerImage {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}