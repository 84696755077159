

.list {
  border: 1px solid var(--neutral20-color);
  max-height: 300px;
  overflow-y: auto;
  border-radius: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  
  &:nth-of-type(2n) {
    background-color: var(--neutral10-color);
  }

  :globl(.form-check) {
    width: 24px;
    height: 24px;
    border-radius: 8px;;
    background-color: var(--neutral20-color);;
  }
}

.imageContainer {
  flex: 0 0 40px;
  margin-right: 8px;
}

.textureName {
  margin-right: auto;
  word-break: break-all;
}

.checkbox.checkbox {
  margin: 0 0 0 1rem !important;
}