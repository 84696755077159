.modal {
  max-width: 540px;
}

.body {
  padding: 40px 56px 48px;
}

.content {
  border-radius: 8px;
}

.title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.24;
  text-align: center;
  margin-bottom: 2rem;;
}

.placeholder {
  min-height:152px;
}

.close {
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  background: var(--neutral20-color);
  border-radius: 8px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}


.selectedFile {
  background: #F9F9F9;
  border: 2px solid var(--success-color);
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
  padding: 8px;
  text-align: center;
  flex-direction: column;
  position: absolute;
  left: -2px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  z-index: 100;
  pointer-events: none;
}

.loader {
  z-index: 101;
}