.popup {
  position: fixed;
  left: var(--curator-sidebar-width);
  top: var(--curator-header-height);
  height: calc(100% - var(--curator-header-height));
  width: var(--curator-open-sidebar-width);
  z-index: 103;
  background-color: red;
  padding: 1rem;
  background-color: #3D4145;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: #fff;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}