.error {
  color: var(--error) !important;
}

m-bottom-1 {
  margin-bottom: 1rem;
}

.m-bottom-2 {
  margin-bottom: 2rem;
}

.pointer {
  cursor: pointer;
}

.hide {
  display: none !important;
}

.page-wrapper {
  padding-right: 0;
  & .row:first-child {
    margin-right: 0 !important;
  }
}

.mb0 {
  margin-bottom: 0px !important;
}

.btnoutline p {
  margin-left: 10px;
}
.mrg-rt {
  margin-right: 1rem;
}
.mrg-rt6 {
  margin-right: 6rem;
}
.btnwidth {
  width: 132px !important;
}
.height {
  height: 40px;
}
.allpadding {
  padding-top: 18px;
  padding-right: 24px;
}
.d-flex .allpadding img {
  border-radius: 12%;
}
.leftbackground {
  width: 100%;
  background: transparent;
  border-radius: 8px;
  padding: 2%;
}
.leftbackgrounds {
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 2%;
}
.p-0 {
  padding: 0% !important;
}
.p-0 .projects-texture-state .container-fluid .texturepreview-right .texturezoom-preview img {
  margin: 0px;
}
.linktrg {
  color: #0159f1 !important;
}
.amountrate {
  color: black;
  font-size: 24px;
}

.mrg-rt4 {
  margin-right: 4rem;
}
.mt-4 {
  margin-top: 4 !important;
}
.mt-30 {
  margin-top: 30px;
}
.mt-18 {
  margin-top: 18px;
}
.fntweightmore {
  font-weight: 400 !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
}
.orngbtn {
  background-color: #ffa200 !important;
  width: 100%;
}

.block {
  display: block !important;
}

.borderradius {
  border: 1px solid #ced4da;
  padding: 6px;
  border-radius: 4px;
}

.trnsparent {
  color: transparent;
}

.fullWidth {
  width: 100% !important;
}

.minWidth200 {
  min-width: 200px;
}
.minWidth165 {
  min-width: 165px;
}
.minWidth135 {
  min-width: 135px;
}

.spaceEvenly {
  justify-content: space-evenly;
}

.fontSize85 {
  font-size: 0.85em;
}

.hide-btn {
  display: none !important;
}

.error-red {
  color: red;
}

.border-none {
  border: none;
}

.rounded-custom-1 {
  border-radius: 0.5rem;
}

.edit-pw-modal {
  .modal-content {
    border-radius: 0.5rem;
  }
}

.topProductButtons {
  max-height: 40px;
}

.cursorPointer {
  cursor: pointer;
}

.font16 {
  font-size: 16px !important;
}

.teamMemberFilter {
  height: 64px;
  margin-bottom: 10px;
}

.teamMemberSidebar {
  margin-top: 8px
}

.dottedBorderBottom {  
  border-bottom: 1px dotted var(--light-grey-1);
}

// .sidebarMenuLine {
//   width: 80%;
// }

.bigButton {
  display: table-cell;
  vertical-align: bottom;
  text-align: center;
}

.lfSelect__menu, .lfSelect__control, .lfSelect__menu-list {
  width: 158px !important;
}

.lfSelect__control {
  height: 40px !important;
  border-radius: 8px !important;
}
.lfSelect__menu {
  border-radius: 8px !important;
}
.lfSelect__menu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 8px !important;
  border-color: var(--favourite-bg-color) !important;
  border-style: solid;
  border-width: 1px;
}

.lfSelect__option {
  height: 56px !important;
  font-size: 16px !important;
  justify-content: center;
  padding-top: 16px !important;

  &.lfSelect__option--is-selected {
    background-color: white !important;
    color: inherit !important;
    font-weight: bolder;
    // border-top: 1px solid var(--neutral60-color) !important;
  }
  &.lfSelect__option:first-of-type {
    border-top: none !important;   
    background-color: var(--neutral10-color) !important;
    color: var(--neutral60-color) !important;
    border-bottom: 1px solid var(--neutral60-color) !important;
  }
  &.lfSelect__option--is-focused:first-of-type {
    background-color: #DEEBFF !important;
  }
  
  &.lfSelect__option--is-selected:first-of-type {    
    font-weight: bolder;
  }
  &.lfSelect__option--is-selected:last-of-type {
    border-bottom: none !important;   
  }
  // &:hover {
  //   background-color: var(--neutral10-color) !important;
  //   color: var(--neutral60-color) !important;
  // }
}

.navbar#commonMenu, .navbar.publicMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%
}

.navbar.publicMenu {
  height: 72px !important;
  z-index: 100;
}

.publicContent {
  padding-top: 85px !important;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  justify-content: center;
}

.startButton {
  width: 99px;
  height: 48px;
}

.lfSelectMulti__control {
  padding-left: 0px !important;
  padding-right: 16px !important;
  background-color: white !important;
  min-height: fit-content !important;
  height: fit-content !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  // box-sizing: content-box;
  overflow-y: auto !important;
  // -webkit-align-items: center;
  // -webkit-box-align: center;
  // -ms-flex-align: center;
  // align-items: center;
  // background-color: #e9e9e9;
  // border-color: #cccccc;
  // border-radius: 8px;
  // border-style: solid;
  // border-width: 1px;
  // cursor: default;
  // display: flex;
  // -webkit-box-flex-wrap: wrap;
  // -webkit-flex-wrap: wrap;
  // -ms-flex-wrap: wrap;
  // flex-wrap: wrap;
  // -webkit-box-pack: justify;
  // -webkit-justify-content: space-between;
  // justify-content: space-between;
  // min-height: fit-content;
  // outline: 0!important;
  // position: relative;
  // -webkit-transition: all 100ms;
  // transition: all 100ms;
  // box-sizing: border-box;
  // border: 1px solid #ced4da;
  // padding: 0.4rem 0.4rem;
  // width: 100%;

}

.lfSelectMulti__value-container {
  // display: contents;
  // box-sizing: content-box;
  &::-webkit-scrollbar {
    display: none;
  }
  // min-height: fit-content !important;
  // height: fit-content !important;
  overflow-y: scroll !important;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */

}

.lfSelectMulti__multi-value {
  // display: contents;
}

.interestImage {
  min-height: 216px !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
} 

.disabled {
  pointer-events:none;
  opacity:0.6;
}