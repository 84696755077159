.fullWidth {
  width: 100% !important;
}

.smallSize.smallSize {
  :global(.thumbnail-title) {
    font-size: 12px;
    line-height: 18px;
    padding-top: 2px;
    margin-bottom: 0;
  }

  :global(.thumbnail-etime) {
    font-size: 10px;
    line-height: 1.3
  }

  :global(.card-body.thumbnail-info) {
    padding: 0 8px 5px;
    min-height: 38px;
  }
}

.favouriteLoading {
  pointer-events: none;
  opacity: 1 !important;
}