.root.root {
  width: 100%;
  // margin: 34px auto;
  border-radius: 10px;
  position: relative;
  margin: 0;


  .input {
    max-width: none;
    height: 48px;
    border-radius: 8px;
    padding-left: 40px;
  }
}


.searchIcon,
.filterButton {
  margin-left: 10px;
  position: absolute;
  display: grid;
  align-items: center;
  height: 100%;
}
.searchIcon {
  left: 12px;
  margin-left: 0;
}

.filterButton {
  right: 10px;
  color: #5A5C61;
  cursor: pointer;
  width: 32px;
  height: 32px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.filterButtonActive, &:hover {
    background: #0059F1;
    border-radius: 6px;
    color: #fff;
  }

  img {
    margin-bottom: 0;
  }
}
