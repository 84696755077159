.pagination {
  font-size: 1rem;
  
  &.fullWidth {
    width: 100%;

    :global(.page-item) {
      width: 100%;
      text-align: center;
    }
  }

  :global(.page-item:last-child .page-link) {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  :global(.page-item:first-child .page-link) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  :global(.page-item.active .page-link) {
    // border: 1px solid #E9E9E9;
    border-color: var(--neutral20-color);
  }


  :global(.page-link) {
    height: 40px;
    color: var(--neutral80-color);
  }

  &.withSpacing {
    :global(.page-item) {
      margin-right: 1px;

      &:last-of-type {
        margin-right: 0;
      }
    }
    :global(.page-link) {
      margin-right: 1px;
      
    }
  }

  &.dark {

    :global(.page-link) {
      background-color: var(--neutral80-color);
      border: none;
      color: var(--white-color);

      &:hover {
        background-color: var(--secondary-color);
      }
    }

    :global(.page-item.active .page-link) {
      background-color: var(--secondary-color);
    }
  }

  &.small {
    :global(.page-link) {
      padding: 0 1rem;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.875rem;
    }
  }
}
