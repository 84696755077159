.unityContainer {
  position: absolute;
  width:  calc(100% - var(--curator-sidebar-width));
  bottom: 0; // var(--curator-header-height);
  left: var(--curator-sidebar-width);
  height: calc(100vh - var(--curator-header-height));
  // height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  :global(.unity-container) {
    height: calc(100vh - var(--curator-header-height)) !important;
    width: 100% !important;
  }
}