.editInput {
  height: 32px;
  outline: none;
  border: none;
  border-radius: var(--curator-border-radius);
  padding: 0 12px;
}

.cancelBtn {
  height: 32px;
  width: 32px;
  cursor: pointer;
  
}

.submitBtn {
  height: 32px;
  flex: 0 0 32px;
  color: #5A5C61;
  cursor: pointer;

  &.active {
    color: var(--success-color);
  }
}

.formContainer {
  display: flex;
  gap: 8px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--black-color);
  z-index: 5;
  padding-top: 4px;
}