.popup {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: red;
  z-index: 10;
  padding: 1rem;
  background-color: #3D4145;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.contentContainer {
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: var(--black-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.scrollableContent {
  height: 100%;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 10px;
    // background: var(--secondary-color);
    border-radius: 8px;
    background: transparent;
    width: 0px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    width: 3px;
    border-radius: 8px;
  }
  
 &::-webkit-scrollbar-thumb {
    background: #bebdbd;
    background: var(--secondary-color);
    border-radius: 8px;
    width: 3px;
  }
}