.button {
  background: #CCDEFC;
  border-radius: var(--curator-border-radius);
  color: var(--secondary-color);
  width: 100%;
  font-size: 16px;
  height: 56px;

  &:hover {
    color: var(--secondary-color);
    background: #e0ebfd;
  }

  [disabled] {
    background: #E5EEFE;
    border-radius: 8px;
    color: #99BDF9;
  }
}