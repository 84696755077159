.root {
  width: 32px;
  height: 32px;
  border: 4px solid currentColor;
  border-left: 4px solid transparent;
  border-radius: 50%;
  animation: loadingAnimation 0.5s linear 0s infinite;

  @keyframes loadingAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

}