.styleOnlyLink {
  color: #A3A4A6 !important;

  &:hover, &:global(.active) {
    color: var(--white-color) !important;
  }
}

.moveDropdown {
  :global(.dropdown-menu) {
    left: 8px !important;
    transform: none !important;
    margin-top:0;

    &:before {
      transform: translateX(80px);
      left: 0;
    }
  }

  &.dropdownStyleOnly {
   :global(.dropdown-toggle) {
     background-color: var(--secondary-color);
   } 
  }
}