.form {
  display: flex;
  gap: 8px;

  .input {
    height: 48px;
    border: none;
    padding: 0 12px;
    border-radius: 8px;
  }
}