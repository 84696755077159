.form {
  :global(.form-label) {
    color: var(--neutral80-color);
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 8px;
  } 

  .projectNameInput{
    padding: 12px;
    background: var(--neutral20-color);
    border: none;

    &::placeholder {
      font-size: 1rem;
    }
  }
}