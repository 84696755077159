.button {
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: 0 20px;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  color: #fff;
  display: flex;
  align-items: center;
  flex: 0 0 121px;

  &.loading {
    pointer-events: none;
  }

  svg {
    margin: 0 10px 0 0;
  }
}