.root {
  position: absolute;
  width: auto;
  left: var(--curator-sidebar-width);
  top: var(--curator-header-height);
  color: #fff;
  background-color: #3d4145;
  height: calc(100% - var(--curator-header-height));
  z-index: 101;
  width: var(--curator-open-sidebar-width);

  &.fixed {
    position: fixed;
  }

  &.inside {
    left: 0;
    top: 0;
    height: 100%;
  }

  &.expanded {
    width: auto;
    right: calc(var(--curator-filter-width) + 17px); // 16px width of teh button on the right side of the sidebar
  }
}

.inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }
}

.fixedHeader {
  position: sticky;
  top: -1rem;
  margin-top: -1rem;
  padding-top: 1rem;
  z-index: 20;
  background-color: #3d4145;
  padding-bottom: 1rem;;
}

.button {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 100%;
  // left: var(--curator-open-sidebar-width);
  width: 16px;
  height: 80px;
  z-index: 101;
  border: none;
  background-color: transparent;
  outline: none;
  background: url(../../../../assets/images/curator/arrow-bckg.svg) no-repeat center center;
  background-size: cover;
  color: #fff;

  &.butonFilterLocation {
    // left: var(--curator-filter-width);
  }

  img {
    margin: 0;
    outline: none;
  }

  .imgRotate {
    transform: rotate(180deg);
  }

  .imgCollapse, .imgExpand {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    margin: auto;
  }

  .imgCollapse {
    transform: rotate(90deg) translateY(5px);

    &.translated {
      transform: rotate(90deg) translateY(13px);
    }
  }

  .imgExpand {
    transform: rotate(-90deg) translateY(-1px);
  }
}

.filter {
  position: absolute;
  left: calc(var(--curator-sidebar-width) + var(--curator-open-sidebar-width));
  top: var(--curator-header-height);
  color: #fff;
  background-color: #3d4145;
  height: calc(100% - var(--curator-header-height));
  border-left: 1px solid #595c61;
  z-index: 101;
  width: var(--curator-filter-width);

  &.filterExpanded {
    right: 17px;
    left: auto;
  }
}