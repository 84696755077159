.button {
  padding: 0 3px;
  width: 100%;
  height: 56px !important;

  &.blueButton {
    background: #CCDEFC;
    color: var(--secondary-color);
    border: none;
  }
}

.loader {
  z-index: 1000;
}