
.dropdown {
  position: relative;
  width: 47px;
  height: 40px;
  cursor: pointer;

  .value {
    color: var(--neutral40-color);
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      color: var(--white-color);
    }
  }

  &.open {
    .dropdownList {
      display: block;
    }
  }

  .dropdownList {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #3D4145;
    z-index: 5;
    width: fit-content;
    display: none;

  }

  .dropdownListItem {
    padding: 0.5rem 1rem;
    cursor: pointer;
    

    &:hover {
      background: var(--neutral80-color);
    }
  }
}