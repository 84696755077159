.button {
  background: #3D4145;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  width: 40px;
  color: var(--white-color);

  &:hover {
    background: var(--neutral80-color);
  }
}

