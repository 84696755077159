// Vars
:root {
  --red: #ff0000;
  --error: #e4291d;
  --menu-shadow-color: #e2e2e2;
  --menu-dark-shadow-color: #444446;
  --divider: #545456;
  --font-family: 'Roboto', sans-serif;
  --header-font-size: 0.9em;
  --base-font-size: 1em;
  --pg-title-font: 2em;
  --card-actions-border-radius: 8px;
  --sidebar-width: 235px;
  --favourite-bg-color: rgba(45, 49, 54, 0.5);
  --border-radius: 8px;
  --thumbnail-width: 272px;
  --thumbnail-info-height: 56px;
  --light-blue: #e5eefe;
  --light-grey: #f5f5f5;
  --light-grey-1: #929292;
  --render-done: #1bb75e;
  --render-failed: #e4291d;
  --render-selected: #0059f1;
  --render-unselected: var(--neutral20-color);
  --table-cell-width: 175px;
  --curator-header-height: 56px;
  --curator-open-sidebar-width: 420px;
  --curator-sidebar-width: 94px;
  --modal-render-width: 625px;
  --modal-preview-width: 375px;
  --light-yellow: #FFDA99;
  --header-bg-color: #ffffff;
  --header-height: 64px;
}

.theme-container.theme-dark {
  --primary-color: #ffa200;
  --secondary-color: #5795ff;
  --white-color: #e6e6e6;
  --black-color: #1b1b1e;
  --background-color: #27272b;
  --neutral80-color: #a2a2a3;
  --neutral60-color: #767678;
  --neutral40-color: #444446;
  --neutral20-color: #333336;
  --neutral10-color: #232326;
  --success-color: #1bb75e;
  --warning-color: #da8622;
  --error-color: #e4291d;
  --error-background-color: #fceae8;
}

.theme-container.theme-light {
  --primary-color: #ffa200;
  --secondary-color: #0059f1;
  --white-color: #ffffff;
  --black-color: #2d3136;
  --background-color: #f6f6f6;
  --neutral80-color: #5a5c61;
  --neutral60-color: #a3a4a6;
  --neutral40-color: #c4c4c7;
  --neutral20-color: #e9e9e9;
  --neutral10-color: #f9f9f9;
  --success-color: #1bb75e;
  --warning-color: #da8622;
  --error-color: #e4291d;
  --error-background-color: #fceae8;
}

// Elements
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Roboto';
}

html,
body,
.site-wrapper {
  @include fluid();
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.site-wrapper {
  @include d-flex();
  @include fluid();
  background-color: $body-bg;
}

body {
  margin: 0;
  padding: 0;
  font-size: var(--base-font-size);
  font-family: var(--font-family);
  font-weight: 400;
  background-color: #f6f6f6 !important;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: gainsboro;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #bebdbd;
  border-radius: 8px;
}

.app {
  height: 100vh;
  background-color: var(--background-color) !important;
}

a {
  text-decoration: none !important;
  &hover {
    color: unset;
  }
}

.app.dark-theme {
  background-color: var(--background-color);

  .common-nav,
  .wrapper .sidebar {
    background-color: var(--black-color);
    box-shadow: 1px 0 0 var(--menu-dark-shadow-color);
  }
  .common-nav {
    .navbar-collapse {
      background-color: var(--black-color);
      box-shadow: none;
    }
  }
  .navbar-light {
    .dropdown-divider {
      border-color: var(--divider);
    }
    .navbar-nav {
      .nav-link {
        color: var(--white-color);
      }
    }
    .sidebarNav {
      min-height: 50px !important;
      &.active {
        & > a,
        > svg,
        > img {
          color: blue !important;
          & > img {
            color: blue !important;
            fill: blue !important;
          }
        }
      }
    }
  }

  .pg-title {
    color: var(--white-color);
  }

  .thumbnail-info {
    .thumbnail-title {
      color: var(--white-color);
    }
    .inner-action a span,
    .inner-action a span::before,
    .inner-action a span::after {
      background-color: var(--white-color);
    }
  }
}

// Responsiveness
@media only screen and (max-width: 576px) {
}
@media only screen and (min-width: 577px) and (max-width: 767px) {
}
@media only screen and (max-width: 767px) {
  .tabs {
    padding-bottom: 0.25em;
    overflow-x: auto;

    li {
      min-width: 125px;
    }
  }
}
@media only screen and (min-width: 768px) {
}
@media only screen and (max-width: 991px) {
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 1199px) {
  .app.dark-theme {
    .common-nav {
      .navbar-collapse {
        border-bottom: 1px solid var(--white-color);
      }
    }
  }
  .common-nav {
    flex-wrap: nowrap;

    .navbar-brand {
      order: 1;
    }
    .header-right-section {
      order: 2;
    }
    .navbar-toggler {
      margin-left: 1em;
      padding: 0.25rem;
      order: 3;
      box-shadow: none;
      border-radius: var(--border-radius);

      span {
        width: 1.275em;
        height: 1.275em;
      }
    }
    .navbar-collapse {
      position: absolute;
      width: 100%;
      padding: 1.5em;
      order: 4;
      top: 60px;
      left: 0;
      background-color: var(--white-color);
      z-index: 1000;
      box-shadow: 2px 5px 25px var(--menu-shadow-color);
    }
  }
}
