.sidebar {
  left: var(--curator-sidebar-width);
  height: calc(100% - var(--curator-header-height));
  top: auto;
  bottom: 0;
  border-top: none;
}

.sidebarFullscreen {
  left: 0;
  height: 100%
}

.preview {
  left: calc(var(--curator-sidebar-width) + var(--sidebar-width));
  height: calc(100% - var(--curator-header-height));
  border-top: none !important; 
}

.previewFullscreen {
  left: var(--sidebar-width);
  height: 100%
}