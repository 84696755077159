.root {
  display: flex;
  align-items: center;


  &.smallScreen {
    position: absolute;
    right: 232px;
    background: #3D4145;
    z-index: 10;
    justify-content: flex-end;

    &.open {
      left: 0;
    }
  }

  
}

.container { 
  display: flex;
  align-items: center;
}

.arrowButton {
  background-color: transparent;
  border: none;
  outline: none;
  height: var(--curator-header-height);
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  font-size: 24px;
  margin-right: -1rem;
}