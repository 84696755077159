.resolutionButton {
  background: #5a5c61;
  border-radius: 8px;
  color: var(--white-color);
  width: 100%;

  &:hover {
    color: var(--white-color);
  }
}

.textInput {
  background: #5a5c61;
  border-radius: 8px;
  height: 48px;
  color: var(--white-color);
  border: none;
}

.fovContainer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .fovInput {
    border: 1px solid var(--neutral20-color);
    border-radius: 8px;
    width: 64px;
    height: 32px;
    text-align: center;
    padding: 0 4px;
  }
}


.objectButton {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  height: 32px;
  border-radius: 8px;
  padding: 0 0.5rem;
  max-width: 178px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  
  &.selected {
    border-color: var(--secondary-color);
    color: var(--secondary-color);

    &:hover {
      // background-color: var(--secondary-color);
      // color: var(--white-color);
      background-color: transparent;
      color: var(--secondary-color);
    }
  }

  .text {
    max-width: 75px;
    text-overflow: ellipsis;
    box-sizing: content-box;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    margin-right: 4px;

    
  }

}

.saveButton {
  padding: 0;
  width: 32px;
  height: 32px;
}

.nameInput {
  background: var(--neutral20-color);
  color: var(--neutral80-color);
  border: none;
  border-radius: 8px;
  width: 100%;
  margin-right: 10px;
  height: 32px;
}