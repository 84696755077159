.root {
  display: inline-flex;
  flex-direction: column;

  input {
    height: 40px;
    border-radius: 8px 0px 0px 8px;
    background: #3D4145;
    border: none;
    outline: none;
    padding: 0 16px;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    width: 100%;
    margin-right: 2px;
  }
}

.inputContainer {
  display: flex;
  justify-content: space-between;
}

.colorPlaceholder {
  border-radius: 0px 8px 8px 0px;
  flex: 0 0 64px;
}

