.root {
  width: 100%;
  background: #2D3136;
  border-radius: 8px;
  padding: 1rem;
}

.colorPicker {
  box-sizing: border-box !important;
  padding: 0 !important;
  border-radius: 8px 8px 0px 0px;
  width: 100% !important;

  :global(.flexbox-fix) {
    display: none !important; // TODO: find a way to use react color picker without rewriting it's styles
  }
}

.slider {
  height: 40px !important;
  border-radius: 0px 0px 8px 8px;
  margin-top: 2px;

  & > div:nth-of-type(1) {
    height: 40px !important;
    border-radius: 0px 0px 8px 8px;

    & > div > div {
      border-radius: 0px 0px 8px 8px;
    }
  }

  & > div:nth-of-type(1) > div > div > div > div {
    height: 40px !important;
  }

  & > div:nth-of-type(2) {
    display: none !important; // TODO: this is hacky solution but react color doesn't provide other
  }
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
}


