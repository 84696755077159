.topMenuItem {
  :global(.dropdown-toggle) {
    padding-left: 25px;
    padding-right: 25px;

    &:first-of-type {
      width: var(--curator-sidebar-width);


      & + :global(.dropdown-menu) {
        left: 16px !important;
        transform: none !important;
        margin-top:0;

        &:before {
          transform: translateX(7px);
          left: 0;
        }
      }
    }
  }
}