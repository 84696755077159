.root {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral60-color);
  border-radius: 8px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.infoText {
  color: var(--neutral60-color);
  white-space: nowrap;
}

.listItem {
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  color: var(--white-color);
  line-height: 1.5;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &:first-child {
    border-bottom: 1px solid var(--neutral60-color);
    position: sticky;
    top: 0;
    background: var(--black-color);
    z-index: 10;
  }

  &:nth-of-type(2n + 3) {
    background-color: var(--neutral80-color);
  }

  .formCheck {

  }

  .label {
    margin-right: auto;
    font-size: 1rem;
    cursor: pointer;
  }

  .resolution {
    margin-left: 1rem;
  }

  input[type="checkbox"]{
    background: var(--neutral20-color);
    margin-top: 0;
  }
}
