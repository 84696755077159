
.sliderContainer {
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-right: 68px;
}

.scrollableContainer {
  overflow-y: auto;
  display: flex;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  &.withArrows {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.observableDiv {
  height: 20px;
  width: 1px;
  flex: 0 0 1px;
  background-color: transparent;
}

.selectedFilter {
  padding: 0 5px 0 8px;
  height: 32px;
  border: 1px solid #A3A4A6;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  line-height: 1.3;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  .deleteButton {
    color: #A3A4A6;
    margin-left: 2px;
    padding: 3px;

    &:hover {
      cursor: pointer;
      color: #fff;
    }
  }
}

.clearButton {
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 400;
  background-color: var(--neutral20-color);
  font-size: 12px;
  color: var(--black-color);
  height: 32px;
  padding: 0 8px;
  border-radius: 8px;
  width: 60px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;

  &:hover {
    background-color: var(--white-color);
  }
}

.slider {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.nextButton, .prevButton {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: red;
  width: 32px;
  height: 34px;
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: #3d4145;
}

.prevButton {
  text-align: left;
  left: 0;
  
  svg {
    transform: rotate(90deg);
  }
}
.nextButton {
  text-align: right;
  right: 67px;

  svg {
    transform: rotate(-90deg);
  }
}