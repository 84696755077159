.root {
  background: var(--black-color);
  border: 1px solid var(--neutral80-color);
  box-sizing: border-box;
  border-radius: 8px;
  position: fixed;
  color: var(--white-color);
}

.menuItem {
  padding: 0.75rem 1rem;
  line-height: 1.5;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;

  &:first-child {
    border-top-left-radius: 8px;;
    border-top-right-radius: 8px;;
  }

  &:last-child {
    border-bottom-left-radius: 8px;;
    border-bottom-right-radius: 8px;;
  }

  &:hover {
    background-color: #3d4145;

    .submenu {
      display: block;
    }
  }

  &.disabled {
    color: var(--neutral80-color);
    pointer-events: none;
  }

  .icon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    font-size: 18px;
    
    svg {
      color: inherit;
    }
  }
}

.productIcon {
  width: 16px;
  height: 16px;
}

.submenu {
  background: var(--black-color);
  left: 100%;
  top: 0;
  position: absolute;
  border-radius: 8px;
  border-top-left-radius: 0;
  overflow: hidden;
  display: none;

  .submenuLeft & {
    left: auto;
    right: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 0;
  }

  .submenuBottom & {
    top: auto;
    bottom: 0;
    border-bottom-left-radius: 0;
  }

  .submenuLeft.submenuBottom & {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0;
  }

  .submenuItem {
    padding: 0.75rem 1rem;
    line-height: 1.5;
    font-size: 0.875rem;
    white-space: nowrap;
    min-height: 48px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #3d4145;
    }
  }
}
