.container {
  position: relative;

  .input {
    padding-right: 45px;
  }
}

.input {
  border-radius: 8px;
}

.arrowContainer {
  position: absolute;
  right: 0;
  width: 35px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #C4C4C7;;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #C4C4C7;
    height: 1px;
  }

  
}

.arrowUp, .arrowDown {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 17px;
    height: 17px;
  }
}

.arrowUp {

  svg {
    transform: rotate(180deg);
  }
}

.arrowDown {
}