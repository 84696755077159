.thumbnailContainer {
  position: relative;

  .texturePlaceholder {
    width: 100%;
    padding-bottom: 56.25%;
    border-radius: 8px;
  }
}

.infoIcon {
  position: absolute;
}

.infoIcon {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 24px;
  height: 24px;
  opacity: 0.5;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}


.materialsInfo {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  // min-height: 100px;
  top: calc(var(--curator-header-height));
  // right: 0;
  left: calc(var(--curator-sidebar-width) + var(--curator-open-sidebar-width));
  overflow: hidden;
  pointer-events: none;
  

  &.open {
    pointer-events: initial;

    .innerContainer {
      transform: translateX(0);
    }

  }

  .innerContainer {
    width: auto;
    display: inline-flex;
    gap: 10px;
    padding: 1rem;
    background: #3D4145;
    border-radius: 0px 8px 8px 0px;
    flex-wrap: wrap;
    transform: translateX(-100%);
    transition: all 0.2s ease;
    min-height: 331px;
  }
}

.cardContainer {
  cursor: pointer;
}

.cardLabel {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--white-color);
  text-align: center;
  padding: 10px 10px 0;;
}

.fitTextureButton {
  
  &:active {
    // box-shadow: 0 -1px 4px 3px #6a552e inset !important;
    background-color: #ffbb3e;
  }
}