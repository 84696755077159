.card {
  border-radius: 8px;
  background-color: #3D4145;
  
  img {
    // width: 100%;
    // height: 176px;
    // object-fit: cover;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    cursor: pointer;
  }

  .cardTitle {
    font-size: 16px;
    line-height: 1.5;
    color: var(--white-color);
    padding: 0.5rem 1rem;


    svg {
      margin-left: 16px;
    }
  }
}