.root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.item {
  background-color: #2d3136;
  padding: 16px 50px 16px 16px;
  border-radius: 8px;
  width: 100%;
  align-items: center;
  display: flex;
  filter: grayscale(1);
  cursor: pointer;
  font-size: 1rem;;
  
  &:hover {
    filter: grayscale(0.35);
  }

  &.enabled {
    filter: grayscale(0);
  }

  

  .text {
    padding-left: 10px;
  }
}