.swatchesContainer {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.swatchItem {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.selected {
    &:before {
      content: '';
      top: calc(100% + 4px);
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 28px;
      height: 3px;
      background-color: var(--secondary-color);
      position: absolute;
    }
  }
}

.btn {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1.25rem;
  color: var(--white-color);
  width: 32px;
  height: 24px;
}

.deleteIcon {
  width: 28px;
  height: 28px;
  background: rgba(45, 49, 54, 0.5);
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease;

  .swatchItem:hover & {
    background: rgba(45, 49, 54, 0.7);
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
}