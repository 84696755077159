.closeButton {
  height: 24px;
  width: 24px;
  background: var(--neutral80-color);
  border-radius: 8px;
  position: absolute;
  top: 20px;
  right: 1rem;
  border: none;
  outline: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 9px;
  }
}

.title {
  font-size: 1.5rem;
}

.previewRenderForm {
  // flex: 0 0 311px;
  width: 100%;
}

.modalDialog {
  margin-top: calc(var(--curator-header-height) + 1em);
  margin-left: auto;
  margin-right: 0.75em;
  color: #fff;
  max-width: 655px;

  &.small {
    max-width: 363px;
  }

  :global(.form-label) {
    font-size: 0.875rem;
  }
}

.modalContent {
  background: var(--black-color) !important;
  border: none;
  border-radius: 8px;
}

.container {
  display: flex;
  gap: 1rem;
  align-items: flex-end;

  &.containerColumn {
    flex-direction: column;
    align-items: stretch;
  }
}
.resolutionInput {
  :global(.form-control) {
    background-color: var(--neutral80-color);
    color: var(--white-color);
    border: none;
    border-radius: 8px;
  }
}



.renderCameraList {
  height: 318px;
  flex: 0 0 296px;
}

.previewCameraList {
  max-height: 318px;
}