.root {
  background: var(--black-color);
  z-index: 5;
  margin-left: -1rem;
  position: sticky;
  margin-right: -1rem;
  padding: 2.5rem 1rem 1rem;
  bottom: 0;
  margin-top: auto;
  // transform: translateY(1rem);
  position: absolute;
  z-index: 15;
  width: 100%;



  .closeButton {
    background: var(--neutral20-color);
    border-radius: 8px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: none;
    outline: none;

    svg {
      width: 10px;
    }
  }
}
