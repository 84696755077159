.range {

  height: 2px;
  background: var(--neutral20-color);
  background-image: linear-gradient(var(--secondary-color), var(--secondary-color));
  // background-image: linear-gradient(#ff4500, #ff4500);
  // background-size: 70% 100%;
  background-repeat: no-repeat;

  &.large {
    height: 4px;
    border-radius: 8px;

    &::-webkit-slider-thumb {
      margin: 0;
      transform: translateY(calc(-50% + 1px));
      background: var(--white-color);
      border: 2px solid var(--secondary-color);
      width: 24px;
      height: 24px;
    }
  }
  
  &[type=range]::-webkit-slider-runnable-track {
    height: 2px;
    background: transparent;
  }

  &::-webkit-slider-thumb {
    margin-top: -7px;
    background: var(--white-color) !important;
    border: 2px solid var(--secondary-color);
    box-shadow: none !important;
    outline: none !important;
    
  }
  
}