.modal {
  max-width: 540px;
}

.body {
  padding: 40px 48px 48px;
}

.content {
  border-radius: 8px;
}

.title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.24;
  text-align: center;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
}

.description {
  margin-top: -1rem;
  margin-bottom: 1.5rem;;
  text-align: center;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.32;
  color: var(--neutral80-color);
}

.placeholder {
  min-height:152px;
}

.close {
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  background: var(--neutral20-color);
  display: flex;
  border-radius: 8px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
