.input {
  width: 58px;
  height: 32px;
  text-align: center;
  padding: 0 6px;
  border-radius: var(--curator-border-radius);
  border: none;
  outline: none;
  font-size: 14px;
  align-self: center;
  margin-left: -8px;;
}