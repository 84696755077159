.root {
  width: 100%;
}

.contentContainer {
  background-color: var(--black-color);
  border-radius: 8px;
}

.addButton {
  background: #CCDEFC;
  color: var(--secondary-color);
  width: 100%;
  height: 48px;
  width: 100%;

  &:hover {
    color: var(--secondary-color);
  }
}

.scrollableContainer {
  overflow: auto;
  height: 100%;

  &::-webkit-scrollbar {
    // width: 10px;
    // background: var(--secondary-color);
    border-radius: 8px;
    background: transparent;
    width: 0px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    width: 3px;
    border-radius: 8px;
  }
  
 &::-webkit-scrollbar-thumb {
    background: #bebdbd;
    background: var(--secondary-color);
    border-radius: 8px;
    width: 3px;
  }
}