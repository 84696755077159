// :root{
//     --base-font-size: 1em;
// }

// $base-font-size: 1em;

// @media only screen and (min-width: 767px){

//     .titlePG{
//         font-size: $base-font-size * 1.2;
//     }

// }
// // @media only screen and (min-width: 768px) and (max-width: 1024px){

// // .titlePG{
// //     font-size: $base-font-size*1.2;
// // }

// // }
// // @media only screen and (min-width: 1025px) and (max-width: 1200px){

// // .titlePG{
// //     font-size: $base-font-size*1.2;
// // }

// // }
// // @media only screen and (min-width: 1201px) and (max-width: 1400px){

// // .titlePG{
// //     font-size: $base-font-size*1.2;
// // }

// // }
// @media only screen and (min-width: 1401px) and (max-width: 1600px){

// .titlePG{
//     font-size: $base-font-size*1;
// }

// }
// @media only screen and (min-width: 1601px) and (max-width: 1800px){

// .titlePG{
//     font-size: $base-font-size*1.2;
// }

// }
// @media only screen and (min-width: 1801px) and (max-width: 2000px){

// .titlePG{
//     font-size: $base-font-size*1.4;
// }

// }
// @media only screen and (min-width: 2001px) and (max-width: 2300px){

// .titlePG{
//     font-size: $base-font-size*1.6;
// }

// }
// @media only screen and (min-width: 2301px) and (max-width: 2600px){

// .titlePG{
//     font-size: $base-font-size*1.8;
// }

// }
// @media only screen and (min-width: 2601px) and (max-width: 3000px){

// .titlePG{
//     font-size: $base-font-size*2;
// }

// }
.card-grid {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1.5em;
    // overflow: hidden;
}

.thumbnail-card {
    // min-width: calc(var(--thumbnail-width)/1.5);
    // width: 100%;
    width: var(--thumbnail-width);
    // height: var(--thumbnail-width);
    // display: flex;
    // flex-direction: column;
    border-radius: var(--border-radius) !important;
    background-color: var(--neutral20-color);
    border: none;
    overflow: hidden;

    * {
        margin: 0;
        padding: 0;
        font-family: var(--font-family) !important;
    }
}

.thumbnail-card-top-section {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
}

.thumbnail-img {
    position: relative;
    overflow: hidden;

    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
    figure {
        width: 100%;
        padding-top: 79.4%;
        border-radius: var(--border-radius);
        overflow: hidden;
        cursor: pointer;
        position: relative;

        img {
            display: block;
            max-width: 100%;
            width: 100%;
            height: 100%;
            // width: var(--thumbnail-width);
            // height: calc(var(--thumbnail-width) - var(--thumbnail-info-height));
            object-position: center;
            object-fit: cover;
            position: absolute;
            left: 0;
            top: 0;
            border-top-left-radius: var(--border-radius) !important;
            border-top-right-radius: var(--border-radius) !important;
        }
    }
}
.thumbnail-action {
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;

    .thumbnail-action-favourite,
    .thumbnail-action-checkbox {
        opacity: 0;
    }

    .thumbnail-card:hover & {
        .thumbnail-action-favourite,
        .thumbnail-action-checkbox {
            opacity: 1;
        }
    }

    &--selected {
        .thumbnail-action-checkbox {
            opacity: 1;
        }
    }

    &--favourite {
        .thumbnail-action-favourite {
            opacity: 1;
        }
    }
}

.action-list {
    position: relative;
    width: 100%;
    padding: 1em;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    // z-index: 100;

    li {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;

        label {
            position: absolute;
            cursor: pointer;
            width: 20px;
            height: 20px;
            left: 0;
            top: 0;
            border: none;
            border-radius: var(--card-actions-border-radius);
            background-color: var(--white-color);
        }

        label:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 5px;
            top: 6px;
            left: 5px;
            opacity: 0;
            transform: rotate(-45deg);
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
        }

        .favouriteCard + label {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            left: auto;
            right: 0;
            background-color: var(--black-color);
        }

        input[type="checkbox"] {
            display: block;
            width: 15px;
            height: 15px;
            visibility: hidden;
        }

        .selectCard:checked + label {
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
        }

        .selectCard:checked + label:after {
            opacity: 1;
        }

        .favouriteCard + label:after {
            display: none;
        }

        .favouriteCard:checked + label {
            // background-color: var(--red);
            // border-color: var(--red);
        }

        .favouriteCard:checked + label:after {
            opacity: 1;
        }
    }
}

.thumbnail-tag {
    position: absolute;
    width: calc(100% - 2em);
    padding: 1em 1em 0.75em;
    left: 0;
    bottom: 0;

    .tag {
        margin: 0 0.5em 0.5em 0;
        padding: 0.25em 0.65em;
        font-size: 0.62em;
        color: var(--black-color);
        display: inline-block;
        border-radius: 4px;
        background-color: var(--white-color);
    }
}

.thumbnail-info {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    // align-items: flex-start;
    width: 100%;
    // height: var(--thumbnail-info-height);
    padding: 0.375em 1em 0.875em;

    .inner-info {
        flex: 0 0 calc(100% - 20px);
        width: calc(100% - 20px);
    }

    .inner-action {
        flex: 0 0 20px;
        width: 20px;
    }

    .thumbnail-title {
        text-transform: capitalize;
        margin-bottom: 0.25em;
        color: var(--black-color);
        font-size: calc(var(--base-font-size) / 1.25);
        font-weight: 400;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .thumbnail-etime {
        color: var(--neutral80-color);
        font-size: calc(var(--base-font-size) / 1.65);
        font-weight: 400;
    }

    .inner-action {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        .card-action {
            cursor: pointer;
            // padding: 5px 10px;
            padding: 0;
        }

        a,
        div {
            position: relative;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            width: 10px;
            height: 16px;
            color: var(--black-color);
            font-size: 12px;
            cursor: pointer;
        }

        ul {
            position: absolute;
            top: -48px;
            left: 0;
            height: 48px;
            border-radius: var(--card-actions-border-radius);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 1px solid var(--white-color);
            background-color: var(--neutral20-color);

            li {
                img {
                    // width: 100%;
                    max-width: 20px;
                }
            }
        }
    }
}

.render-status {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 4px;
        left: 0;
        bottom: 0;
    }
}

.render-status.render-done {
    &:after {
        background-color: var(--render-done);
    }
}

.render-status.render-failed {
    &:after {
        background-color: var(--render-failed);
    }
}

.render-status.render-selected {
    &:after {
        background-color: var(--render-selected);
    }
}

.render-status.render-unselected {
    &:after {
        background-color: var(--render-unselected);
    }
}

// responsiveness

// @media only screen and (max-width: 576px){
//     .card-grid > div{
//         width: calc(100% - 15px);
//         flex: 0 0 calc(100% - 15px);
//     }
// }
// @media only screen and (min-width: 577px) and (max-width: 767px){
//     .card-grid > div{
//         width: calc(50% - 15px);
//         flex: 0 0 calc(50% - 15px);
//     }
// }
// @media only screen and (min-width: 768px) and (max-width: 1024px){
//     .card-grid > div{
//         width: calc(33.33% - 15px);
//         flex: 0 0 calc(33.33% - 15px);
//     }
// }
// @media only screen and (min-width: 1025px){
//     .card-grid > div{
//         width: calc(25% - 15px);
//         flex: 0 0 calc(25% - 15px);
//     }
// }
