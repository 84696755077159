.menuItem {

  &.disabled {
    opacity: 0.4;
    // cursor: not-allowed;
    // pointer-events: none;
  }

  svg {
    color: #fff;
  }
}

.iconContainer {
  width: 72px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.stickyTopBar {
  position: sticky;
  top: var(--curator-header-height);
  z-index: 2;
  background-color: #3d4145;
}


.unity {
  transition: all 0.2s ease;

  &:not(.unitySmall) {
    transform: translateX(calc( -1 * var(--curator-sidebar-width)));
    width: 100vw !important ;
  }
}

.sidebar {
    width: var(--curator-sidebar-width);
    height: calc(100vh - var(--curator-header-height));
    background-color: #2d3136;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    display: flex;
    transform: translateX(-100%);
    transition: all 0.2s ease;

    &.sidebarVisible {
      transform: translateX(0%);
    }
    
    :global(.main-menu) {
      height: calc(89vh - var(--curator-header-height));
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    :global(.sidebar-content) {
      padding: 0;
      li {
        // padding: 25px 21px;
        height: 96px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #c4c4c7;
        font-size: 14px;
        cursor: pointer;
        &:global(.active) {
          background-color: #3d4145;
        }
        img {
          margin-bottom: 8px;
        }
      }
    }
}