.loaderV2 {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.backdrop {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .backdropDark & {
    background-color: #3d4145;
    opacity: 0.6;
  }
}

.progressContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}