.root {
  align-items: center;
}

.switch {
  width: 48px;
  flex: 0 0 48px;
  margin: 0;
  margin-right: 0.5rem;
  padding: 0;

  input {
    height: 24px;
    width: 100% !important;
  }
}