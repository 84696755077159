$orange: #ffa200;
$warning: $orange;

$border-radius: 1.5rem;
$border-radius-sm: 1.5rem;
$border-radius-lg: 1.5rem;

$btn-border-radius: 0.7rem;
$btn-border-radius-lg: 0.7rem;

$input-border-radius: 0.7rem;
$input-btn-padding-y: 0.7rem;
$input-bg: var(--neutral20-color);

$btn-font-size: 0.75rem;
$btn-font-size-lg: 0.75rem;
