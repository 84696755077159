// Carousel
.carousel.rounded .carousel-inner {
  border-radius: 1.5rem;
}

.carousel-small {
  width: 500px;
  height: 500px;
}

// User Card
.user-card {
  position: relative;

  .card {
    position: absolute;
    width: 150px;
    top: 52px;
    right: 0;
    border-radius: var(--card-actions-border-radius) !important;
    // overflow: hidden;
    background-color: var(--black-color);

    .card-body {
      padding: 0.5rem 0.75rem;
    }

    ul.menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      li {
        width: 100%;
        flex: 0 0 100%;
        color: var(--white-color);

        a {
          color: var(--white-color);
          text-align: left;

          &:hover {
            color: var(--white-color);
          }
        }
      }
    }

    .theme-mode {
      border-top: 1px solid var(--neutral80-color);

      ul {
        &:before {
          display: none;
        }
      }
    }

    .theme-mode-switcher {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;

      .theme-label {
        white-space: nowrap;
      }

      .toggle {
        margin-left: 0.5em;
        background-color: transparent;
        border-radius: 50px;
        border: none;

        .toggle-on {
          background-color: var(--secondary-color);
        }

        .toggle-off {
          background-color: var(--neutral20-color);
        }

        .toggle-group > .btn {
          padding: 0.2em;
        }

        .text {
          width: 15px;
          height: 15px;
          display: block;
          border-radius: 50%;
          opacity: 1;
          font-size: 0;
          background-color: var(--white-color);
        }
      }
    }
  }
}

.card-arrow.navbar-nav {
  position: relative;
}
.card-arrow {
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: -14px;
    right: -5px;
    border-left: 10px solid var(--black-color);
    border-right: 5px solid transparent;
    border-top: 5px solid transparent;
    border-bottom: 10px solid var(--black-color);
    transform: rotate(136deg);
    border-radius: 2px;
  }
}

// Tabs
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  width: auto;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border: none;

  .nav-item {
    flex: 0 0 100px;
    width: 100px;
    border: 1px solid var(--neutral20-color);
    overflow: hidden;

    &:first-child,
    &:first-child button {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
    &:last-child,
    &:last-child button {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }

    button {
      width: 100%;
      height: 100%;
      display: block;
      padding: 0.35em 0.5em;
      font-size: calc(var(--base-font-size) - 2px);
      color: var(--neutral80-color);
      font-family: var(--font-family);
      border-radius: 0;
      background-color: var(--white-color);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    button.active {
      color: var(--white-color);
      background-color: var(--secondary-color);
    }
  }
}

.tabs-2 {
  .nav-item {
    flex: 0 0 auto;
    width: auto;

    button {
      display: flex;
      align-items: center;
      padding: 0.35em 1.5em;
    }
  }
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--error-color);
}

// react select
.select__multi-value {
  background: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 4px 4px;
}
.select__control {
  background: white !important;
  &:focus {
    border-color: black !important;
    box-shadow: 0 0 0 1px black !important;
  }
}
.basic-multi-select .select__control {
  min-height: 54px !important;
}
.css-1pahdxg-control:hover {
  border-color: black !important;
}
.css-1pahdxg-control {
  border-color: black !important;
  box-shadow: none !important;
}
// react tags
.react-tag-input {
  border-radius: 5px !important;
  min-height: 52px !important;
}
.react-tag-input__tag__remove {
  background-color: white !important;
  border: 1px solid black !important;
  border-radius: 50% !important;
  font-size: 9px !important;
}
.react-tag-input__tag {
  border-radius: 5px !important;
  border: 1px solid black !important;
  padding: 8px 4px !important;
  background-color: white !important;
}

// texture
.projects-texture-state {
  .container-fluid {
    display: flex;
    margin-top: 2%;

    .texturepreview-right {
      width: 30%;

      .texturezoom-preview {
        width: 100%;
        background: #ffffff;
        border-radius: 8px;
        padding: 8%;
        img {
          width: 55%;
          margin: 12% 22%;
          border-radius: 8px;
        }
        .zoom-selected {
          background: #2d3136;
          border-radius: 8px;
          width: 152px;
          height: 32px;
          color: #ffffff;
          padding: 1%;
          span {
            font-size: 14px;
            margin-left: 16px;
          }
        }
      }
      .texture-name {
        width: 100%;
        background: #ffffff;
        border-radius: 8px;
        padding: 9%;
        margin-top: 10px;
        margin-bottom: 10%;
        label {
          font-size: 14px;
          color: #5a5c61;
        }
        .texturepreview-btn {
          margin: 2.5%;
        }
        button {
          width: 50px;
          margin-left: 6px;
          margin-right: 6px;
          background: #e9e9e9;
          border-radius: 8px;
        }
        button.btn-selected,
        button:hover {
          background: #2d3136;
          color: #ffffff;
        }
        p {
          margin-top: 10px;
        }
      }
    }
    .texturepreview-left {
      width: 70%;

      .texture3d-previews {
        width: 98%;
        background: #ffffff;
        border-radius: 8px;
        margin-left: 2%;

        .texture3d-header {
          display: flex;
          justify-content: space-between;
          padding: 5%;
        }

        img {
          width: 90%;
          height: 120px;
          -o-object-fit: contain;
          object-fit: contain;
          margin: 25% 6% 55%;
          opacity: 0.5;
        }
        img:hover {
          border: 2px solid #0059f1;
          border-radius: 8px;
          opacity: unset;
        }
        h1 {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          color: #2d3136;
        }
        button {
          width: 32.83px;
          height: 32px;
          background: #2d3136;
          border-radius: 8px;
          border: unset;
          .closebtn {
            height: unset;
            width: unset;
            padding: 4px;
            opacity: unset;
            border: unset;
          }
        }
      }
      .texture-form {
        width: 98%;
        // height: 40%;
        background: #ffffff;
        border-radius: 8px;
        margin-left: 2%;
        margin-top: 2%;
        padding: 20px;
        label {
          font-size: 14px;
          color: #5a5c61;
        }
        //   .form-content{
        //       margin: 2%;
        //   }
      }
    }
  }
}

.close-btn {
  width: 24px;
  height: 24px;
  background: #e9e9e9;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  position: absolute;
  top: -98px;
  right: 0;

  // padding: 20px;
  svg {
    // padding: 10px;
    font-size: 20px;
  }
}

.modal-body {
  .popup-button {
    display: flex;

    .collectionbtn {
      width: 200px;
      border: 2px solid #888888;
      box-sizing: border-box;
      border-radius: 8px;
      padding-left: 10px;
      background: transparent !important;
      height: 50px;
    }
  }
}

.header-name-of-my-projectbtns {
  .container-fluid {
    .scl {
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      flex-wrap: nowrap;
      margin-right: 6px;
    }
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
    ::-webkit-scrollbar {
      width: 0px !important;
      background: transparent !important; /* make scrollbar transparent */
    }
    ::-webkit-scrollbar-track {
      width: 0px !important;
      background: transparent !important;
    }
    ::-webkit-scrollbar-thumb {
      width: 0px !important;
      background: transparent !important;
    }
    .previouscol {
      display: flex;
      .previousarrow {
        margin-right: 20px;
        margin-top: 5px;
      }
      .nextarrow {
        margin-left: 20px;
        margin-top: 5px;
      }
    }
    .bedroom-btn {
      // background: #ffffff;
      border: 1px solid #5a5c61;
      box-sizing: border-box;
      border-radius: 12px 12px 12px 12px;
      .bedroom-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px 12px;
        p {
          margin-bottom: 0px;
        }
        .bedroomclose {
          margin-left: 4px;
        }
      }
    }
  }
  .clrbtn {
    background: #e9e9e9;
    border-radius: 8px;
    p {
      text-align: center;
      padding: 10px 12px;
    }
  }
}

.render-sidebar {
  display: block;
  width: var(--sidebar-width);
  transition: all 0.5s ease;
  background-color: var(--white-color);
  box-shadow: 1px 0px 0px var(--menu-shadow-color);
  z-index: 105;
  overflow: unset !important;
  .main-card-for-sidebar {
    height: 80vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0px !important;

    .main-cards-of-my-project {
      display: flex;
      width: 270px;
      float: left;
      margin: 6px;

      .main-cards-1-of-my-project {
        border-radius: 10px;
        background-color: #e9e9e9;
        position: relative;
        width: 100%;

        .menu-item.custom-checkbox {
          position: absolute;
          top: 10px;
          left: 10px;

          input {
            background: #ffffff;
            border-radius: 8px;
          }
        }

        .card-icon-img-for-mp {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        .menu-of-room-card {
          display: flex;
          width: 100%;
          justify-content: space-around;
          position: absolute;
          background-color: #e9e9e9;
          bottom: 50px;
          padding: 4px;
          border-radius: 8px 8px 0px 0px;
          box-shadow: 0px 1.5px 0px #ffffff;
        }

        .card-txt-img-for-mp {
          padding: 10px 10px 3px;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
        }

        .main-off-on-class-for-card {
          border-bottom: 3px solid green;
          border-radius: 6px;
        }
      }
    }
  }
}

.main-container {
  // width: calc(100% - 235px);
  .myprojectbtns {
    display: flex;
    .head_btns {
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 10px 14px;
      margin-right: 12px;
      img {
        width: 20px;
      }
    }
    .collection-of-my-project {
      margin-right: 1rem;
      .collectionbtn {
        display: flex;
        width: 200px;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 8px;
        padding-left: 10px;
        background: transparent;
        height: 50px;

        p {
          margin-left: 10px;
          margin-top: 11px;
        }
        img {
          width: 20px;
          margin-top: 13px;
        }
      }
    }
    .button-of-my-project {
      margin-right: 1rem;
      .btn-warning {
        background-color: #ffa200;
        border-radius: 8px;
        padding: 10px;
        width: 140px;
      }
    }
  }
  a {
    text-decoration: none;
  }

  .header-name-of-my-projectbtns {
    .container-fluid {
      .scl {
        width: 100%;
        overflow: hidden;
        overflow-x: auto;
        flex-wrap: nowrap;
        margin-right: 12px;
      }
      .scl::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
      }
      .previouscol {
        display: flex;
        .previousarrow {
          margin-right: 20px;
          margin-top: 5px;
        }
        .nextarrow {
          margin-left: 20px;
          margin-top: 5px;
        }
      }
      .bedroom-btn {
        // width: 100px;
        // height: 40px;
        background: #ffffff;
        border: 1px solid #5a5c61;
        box-sizing: border-box;
        border-radius: 12px 12px 12px 12px;

        .bedroom-content {
          display: flex;
          // margin-top: 5px;
          justify-content: space-around;
          align-items: center;
          padding: 10px 12px;
          p {
            margin-bottom: 0px;
          }
          .bedroomclose {
            margin-left: 4px;
          }
        }
      }
    }
    .clrbtn {
      // width: 83px;
      // height: 32px;
      background: #e9e9e9;
      border-radius: 8px;
      // margin-top: 4px;
      p {
        text-align: center;
        padding: 10px 12px;
      }
    }
  }
  .list-of-member-in-dashboard {
    padding: 2%;
    .title-of-table-mp {
      display: flex;
      width: 70%;
      justify-content: space-between;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
    .table-of-list-of-member {
      border-radius: 8px;
      border: 1px solid #e9e9e9;
    }
  }
  .projects-render-state {
    .renderpreviewclose-btn {
      z-index: 1;
      position: absolute;
      right: 2%;
      margin-top: 30px;

      button {
        background: #2d3136;
        opacity: 0.4;
        border-radius: 8px;
        border: unset;
        width: 30px;

        img {
          padding: 8px 4px;
        }
      }
    }

    #carouselExampleControls {
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        background-color: #2d3136;
        border-radius: 8px;
        opacity: 0.4;
      }

      .carousel-inner {
        width: 100%;

        .carousel-item {
          img {
            width: 100%;
            height: 100vh;
            object-fit: cover;
            border-radius: 0px !important;
          }
        }
      }
    }

    .page-par {
      display: flex;
      position: absolute;
      bottom: 12%;
      right: 1%;
      position: fixed;
      z-index: 1;

      .pagination {
        .page-item:first-child {
          border-bottom-left-radius: 5px !important;
          border-top-left-radius: 5px !important;
          width: 100%;
        }

        .page-item:last-child {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }

        .page-link {
          box-shadow: none !important;
          color: var(--neutral80-color);
          font-weight: normal;
          font-size: 16px;
        }

        .page-item.active {
          .page-link {
            color: #ffffff !important;
          }
        }
      }
    }
    .renderdownloadpopup {
      background: #2d3136;
      border-radius: 8px;
      width: 196px;
      height: 150px;
      z-index: 1;
      position: absolute;
      /* bottom: 132%; */
      margin-left: -76px;
      top: -175px;
      ul {
        border-bottom: 1px solid #5a5c61;
        padding: 5px;
        li {
          padding: 12px;
          list-style: none;

          a {
            color: #ffffff;
            margin-right: 50px;
          }
        }
      }
      .render-toggle {
        display: flex;
        justify-content: space-around;
        position: relative;
        right: 35px;
        /* bottom: 10px; */
        top: 15px;
      }
    }
  }
  .renderdownloadpopup::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: -1;
    clip-path: polygon(100% 1%, 0 0, 47% 100%);
    background-color: #2d3136;
    left: 60%;
    margin-top: 25px;
  }

  .footer {
    background: #2d3136;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    // width: 100%;
    width: calc(100% - 296px);

    .footer-heading {
      p {
        padding-top: 10px;
        color: #ffffff;
        margin-left: 15px;
      }
    }

    .footer-content {
      display: flex;
      list-style: none;
      margin: 0px;
      padding-left: 0px !important;
      // margin-right: 160px;

      li {
        a {
          color: #ffffff;
          display: grid;
          justify-items: center;
          font-size: 12px;
        }
      }
      li:hover + .renderdownloadpopup {
        display: block;
      }
    }
  }
}

.projects-empty-states {
  width: calc(100% - 235px);
  .texture-nav {
    padding: 20px 0 7px 24px;
    background-color: #fff;
  }
  a {
    text-decoration: none;
  }

  .header-name-of-my-projectbtns {
    .container-fluid {
      .scl {
        width: 100%;
        overflow: hidden;
        overflow-x: auto;
        flex-wrap: nowrap;
        margin-right: 6px;
      }
      .scl::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
      }
      .previouscol {
        display: flex;
        .previousarrow {
          margin-right: 20px;
          margin-top: 5px;
        }
        .nextarrow {
          margin-left: 20px;
          margin-top: 5px;
        }
      }
      .bedroom-btn {
        // width: 100px;
        // height: 40px;
        background: #ffffff;
        border: 1px solid #5a5c61;
        box-sizing: border-box;
        border-radius: 12px 12px 12px 12px;

        .bedroom-content {
          display: flex;
          // margin-top: 5px;
          justify-content: space-around;
          align-items: center;
          padding: 10px 12px;
          p {
            margin-bottom: 0px;
          }
          .bedroomclose {
            margin-left: 4px;
          }
        }
      }
    }
    .clrbtn {
      // width: 83px;
      // height: 32px;
      background: #e9e9e9;
      border-radius: 8px;
      // margin-top: 4px;
      p {
        text-align: center;
        padding: 10px 12px;
      }
    }
  }
}

// render-settings
.render-settings {
  min-width: 665px;
  background: #2d3136;
  border-radius: 8px;
  // position: relative;
  color: white;
  position: absolute;
  right: 6px;
  bottom: 60px;
  z-index: 2;
  .settings {
    .heading {
      h4 {
        font-weight: 700;
      }
      .close-icon {
        span {
          background: #5a5c61;
          border-radius: 8px;
          padding: 2px 4px;
        }
      }
    }
    .render-content {
      .render-left {
        border: 1px solid #a3a4a6;
        border-radius: 8px;
        .left-header {
          border-bottom: 1px solid #a3a4a6;
          span {
            color: #a3a4a6;
          }
        }
        .render-left-content {
          max-height: 268px;
          border-radius: 0 0 8px 8px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
          > div:nth-child(even) {
            background: #5a5c61;
          }
        }
      }
      .render-right {
        .render-calc {
          .resolution-input {
            .form-control {
              background-color: #5a5c61;
              border: none;
              border-radius: 10px;
              color: white;
              padding: 12px;
              box-shadow: none;
            }
          }
        }
        .render-input {
          .custom-checkbox {
            padding-bottom: 16px;
            .form-check-input {
              background: #5a5c61;
              &.selectAll {
                background: white !important;

                &.select &:focus {
                  box-shadow: unset;
                }
                &:checked[type='checkbox']::before {
                  content: '';
                  width: 12px;
                  height: 8px;
                  position: absolute;
                  border-left: 2px solid #ffffff;
                  border-bottom: 2px solid #ffffff;
                  transform: rotate(-38deg) translate(-50%, -50%);
                  top: 21%;
                  left: 56%;
                  background: transparent;
                  background-image: unset;
                }
                &:checked {
                  background-color: #0059f1 !important;
                  border-color: #0059f1;
                }
                &:checked[type='checkbox'] {
                  background-image: unset;
                  border-radius: 8px;
                }
              }
            }
          }
        }
        .send-btn {
          .btn {
            width: 100%;
            background: #ffa200;
            border-radius: 8px;
            padding: 12px;
          }
        }
      }
    }
  }
}

//save-project-Modal-popup
.save-project-Modal-popup-parent {
  .save-project-modal {
    width: 650px;
    .modal-content {
      padding: 10px;
      background: #ffffff;
      border-radius: 8px;
      .close-btn-head {
        display: flex;
        justify-content: flex-end;
        .close-btn-parent {
          background: #e9e9e9;
          border-radius: 8px;
          position: relative;
          img {
            position: absolute;
            top: 37%;
            left: 35%;
          }
          .btn-close {
            background-image: unset;
            padding: 0 5px;
          }
        }
      }
      .modal-header {
        display: flex;
        flex-direction: column;
        border: none;
        .heading {
          h5#exampleModalLabel {
            font-weight: bold;
            font-size: 32px;
            line-height: 124%;
            text-align: center;
            color: #2d3136;
          }
          .modal-title1 {
            text-align: center;
          }
        }
      }

      .modal-body {
        padding: 0 24px;
        .select-cls-input {
          background: #e9e9e9;
          border-radius: 8px;
          color: #5a5c61;
          font-size: 16px;
          padding: 8px;
        }

        .select-cls-input[value=''] {
          background: #ffffff;
        }

        .select-cls-input:focus,
        .select-cls-input:target,
        textarea:focus,
        select:focus {
          border: none;
          outline: black;
          box-shadow: none !important;
          // padding: 10px;
        }
        .matched-data {
          width: 430px;
          height: 295px;
          background: #ffffff;
          border: 1px solid #2d3136;
          box-sizing: border-box;
          border-radius: 8px;
          overflow-y: auto;
          overflow-x: hidden;

          .listed-data {
            width: 427px;
            height: 71px;
            background: #ffffff;
            border-radius: 8px;
            display: flex;
            padding: 12px 28px;
            justify-content: space-between;
            align-items: center;
            .active {
              background-color: #f9f9f9;
            }
            img {
              border-radius: 8px;
            }
            .owner-data {
              margin-left: 2rem;
              h3 {
                font-size: 16px;
                color: #2d3136;
              }
              p {
                font-size: 12px;
                color: #5a5c61;
              }
            }
          }
          .active {
            background-color: #f9f9f9;
          }
        }
        button {
          width: 100%;
          border-radius: 8px;
          color: #5a5c61;
          font-size: 16px;
          margin-top: 1px;
          padding: 10px;
        }
      }
    }
  }
}

.style-submenu {
  .style-cards {
    &.style-cards-map-mode {
      padding-bottom: 50px;
    }
  }
}
.comment-of-render {
  position: absolute;
  top: 20%;
  right: 15%;
  .render {
    width: 400px;
    .icon-of-comment {
      margin-bottom: 30px;
    }
    .title-of-comment {
      display: flex;
      margin-bottom: 20px;
      .profile-icon {
        background-color: #0059f1;
        width: 48px;
        height: 50px;
        border-radius: 8px;
        img {
          border: solid 8px #0059f1;
          border-radius: 8px;
        }
      }
      .title-info-for-comment {
        display: flex;
        align-items: center;
        width: 15%;
        justify-content: space-between;
        background: #ffffff;
        border-radius: 8px;
        padding: 6px;
        margin-left: 10px;
        p {
          margin: 0;
        }
      }
    }

    .body-of-comment {
      display: flex;

      .profile-icon {
        background-color: #ffa200;
        width: 48px;
        height: 50px;
        border-radius: 8px;
        position: relative;
        margin-bottom: 20px;
        margin-right: 10px;
        img {
          border: solid 8px #ffa200;
          border-radius: 8px;
        }

        &:before {
          content: ' ';
          position: absolute;
          clip-path: polygon(100% 0, 50% 100%, 0 0);
          background-color: red;
          background-color: #ffa200;
          z-index: 1;
          bottom: -9px;
          width: 11px;
          height: 10px;
          left: 35%;
        }
      }
    }
    .info-of-body {
      width: 20%;
      background-color: white;
      .head-of-info-of-comment {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        .main-header-info-of-title {
          display: flex;
          .arrow-icon-in-comment {
            transform: scaleX(-1);
          }
        }
        .option-incomment {
        }
      }
      .actionBox {
        border-top: 1px dotted #bbb;
        padding: 10px;
        .commentList {
          padding: 0;
          list-style: none;
          max-height: 200px;
          overflow: auto;

          .commentList li {
            margin: 0;
            margin-top: 10px;
          }
          .commentList li > div {
            display: table-cell;
          }
          .commenterImage {
            width: 30px;
            margin-right: 5px;
            height: 100%;
            float: left;
          }
          .commenterImage img {
            width: 100%;
            border-radius: 4px;
          }
          .commentText p {
            margin: 0;
            .sub-text {
              color: #aaa;
              font-family: verdana;
              font-size: 11px;
            }
          }
        }

        .footer-of-comment {
          display: flex;
          align-items: center;
          .img-of-team-member {
            width: 30px;
            height: 30px;
          }
          .title-info-for-comment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #e9e9e9;
            border-radius: 8px;
            padding: 6px;
            margin-left: 10px;
            width: 100%;
            input {
              // border: none;
              background-color: #e9e9e9;
            }
            input[type='text'] {
              outline: unset;
            }
          }
        }
      }
    }
  }

  .last-img-for-comment {
    .profile-icon {
      background-color: #e9e9e9;
      width: 48px;
      height: 50px;
      border-radius: 8px;
      position: relative;
      margin-bottom: 20px;
      margin-right: 10px;
      img {
        border: solid 8px #e9e9e9;
        border-radius: 8px;
      }

      &:before {
        content: ' ';
        position: absolute;
        clip-path: polygon(100% 0, 50% 100%, 0 0);
        background-color: #e9e9e9;
        z-index: 1;
        bottom: -9px;
        width: 11px;
        height: 10px;
        left: 35%;
      }
    }
  }
}

// notification
.Notification-Myproject-side {
  position: absolute;
  width: 436px;
  height: calc(100vh - 66px);
  // padding         : 1.5%;
  background-color: #fff;
  top: 60px;
  right: 0;
  transition: 1s;
  overflow-y: scroll;
  transform: translateX(0);
  z-index: 1;
  .notification-header {
    padding: 25px;
    border: 1px solid #e9e9e9;
    .notification-bars {
      .notification-popup {
        background: #2d3136;
        border-radius: 8px;
        z-index: 1;
        position: absolute;
        left: 203px;
        top: 70px;
        ul {
          padding: unset;
          margin: 0;
          li {
            font-size: 16px;
            color: #ffffff;
            list-style: none;
            padding: 15px 20px;
          }
          li:nth-child(3) {
            border-bottom: 1px solid #5a5c61;
          }
        }
      }
      .notification-popup::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        z-index: -1;

        clip-path: polygon(50% 31%, 0% 100%, 100% 100%);
        background-color: #2d3136;
        left: 86%;

        top: -16px;
      }
      // .not-img:hover + .notification-popup{
      //     display: block;
      // }
    }
  }

  .notification-content {
    .notification-dropdown {
      // .filter {
      //     width          : 158px;
      //     height         : 40px;
      //     background     : #ffffff;
      //     border         : 1px solid #e9e9e9;
      //     box-sizing     : border-box;
      //     border-radius  : 8px;
      //     display        : flex;
      //     align-items    : center;
      //     justify-content: space-around;

      // }

      // .sortby {
      //     width          : 158px;
      //     height         : 40px;
      //     background     : #ffffff;
      //     border         : 1px solid #e9e9e9;
      //     box-sizing     : border-box;
      //     border-radius  : 8px;
      //     display        : flex;
      //     align-items    : center;
      //     justify-content: space-around;
      //     margin-left    : 10px;
      // }
      .dropdown {
        .filter {
          width: 158px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #e9e9e9;
          box-sizing: border-box;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 10px;
          .dropdowntext-content {
            margin-left: 12px;
          }
          .dropdownimg-content {
            margin-right: 12px;
          }
        }
        ul {
          p {
            margin-top: 0;
            margin-bottom: 0rem;
            border-bottom: 1px solid gainsboro;
            padding: 10px 25px;
            color: #a3a4a6;
          }
          li {
            padding: 10px;
          }
        }
        .sortby {
          width: 158px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #e9e9e9;
          box-sizing: border-box;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 10px;
          .dropdowntext-content {
            margin-left: 12px;
          }
          .dropdownimg-content {
            margin-right: 12px;
          }
        }
        .dropdown-menu.show {
          display: block;
          border-radius: 8px;
        }
      }
    }

    .status {
      span {
        font-weight: 700;
        font-size: 16px;
        color: #2d3136;
      }
    }

    .messages {
      .card {
        background: #f9f9f9;
        border-radius: 8px;
        border: none;
        padding: 20px 40px;
        border-left: 2px solid #ffa200;
      }

      .card2 {
        background: #f9f9f9;
        border-radius: 8px;
        border: 1px solid #e9e9e9;
        padding: 20px 40px;
      }

      .hours {
        color: #5a5c61;
        font-size: 14px;
      }
    }
  }
}

// common btn style
.myprojectbtns {
  display: flex;
  .head_btns {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 14px;
    margin-right: 12px;
    img {
      width: 20px;
    }
  }
  .collection-of-my-project {
    margin-right: 1rem;
    .collectionbtn {
      display: flex;
      width: 200px;
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      border-radius: 8px;
      padding-left: 10px;
      background: transparent;
      height: 50px;

      p {
        margin-left: 10px;
        margin-top: 11px;
      }
      img {
        width: 20px;
        margin-top: 13px;
      }
    }
  }
  .button-of-my-project {
    margin-right: 1rem;
    .btn-warning {
      background-color: #ffa200;
      border-radius: 8px;
      padding: 10px;
      width: 140px;
    }
  }
}

.preview-and-render .pagination {
  justify-content: center;
  margin-top: 30px;
}
.preview-and-render .page-item {
  width: 47%;
  text-align: center;
}
.preview-and-render a.page-link {
  color: #000;
  box-shadow: none !important;
}
.room {
  background-color: #fff;
  border-radius: 10px;
  flex-basis: 46%;
  margin-bottom: 11px;
  overflow: hidden;
  font-size: 18px;
  position: relative;
}
.room img {
  width: 100%;
}
.room-content h5 {
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
}
.room-content p {
  color: #999;
  font-size: 13px;
  margin-bottom: 5px;
}
.room-sec {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  align-items: center;
}
.both-sec {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: space-evenly;
}
.room i {
  color: #000;
}
.heart {
  position: absolute;
  right: 10px;
  top: 5px;
  background-color: #000;
  padding: 4px 8px;
  border-radius: 10px;
}
.heart i {
  color: red;
}

.cameras {
  padding: 10px;
}
.cameras-image {
  background-color: #fff;
  border-radius: 10px;
  flex-basis: 46%;
  margin-bottom: 10px;
  overflow: hidden;
  font-size: 18px;
  position: relative;
}
.cameras-image img {
  width: 100%;
}
.camera-para {
  margin: 10px;
}
.cameras-sec {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
  justify-content: center;
}
.cameras-h5 {
  text-align: center;
}
.slick-slide img {
  margin: auto;
}

.projects-texture-state .container-fluid .texturepreview-right .texturezoom-preview img.item {
  width: 83% !important;
  margin: 0%;
  border-radius: 8px;
}

.projects-texture-state .container-fluid .texturepreview-lefts {
  width: 68%;
  margin-left: 15px;
  margin-right: 12px;
  margin-top: 12px;
}

.btnborder {
  border: 1px solid #e9e9e9 !important;
}
.btnbackbgcolor {
  background-color: #e9e9e9 !important;
}
img.mrg-rt {
  margin-right: 0.45rem;
}

.searchbtnbg {
  background-color: #e9e9e9 !important;
  border-radius: 0.25rem;
}
.navtpborder {
  border-top: 1px solid #b9bbb652;
}

.maine-box-of-my-project .main-cards-of-my-project.productname {
  width: 390px !important;
}
.projects-texture-state .container-fluid .texturepreview-right .texturezoom-preview img.buton {
  width: 100% !important;
  margin: 0%;
  border-radius: 8px;
  padding-right: 14px;
  cursor: pointer;
}
.titlealign {
  display: flex;
  justify-content: space-between;
  p {
    margin-bottom: 10px;
  }
}

.box {
  display: flex;
}
.box .percent {
  width: 150px;
  height: 150px;
  position: relative;
}
.box .percent svg {
  width: 150px;
  height: 150px;
  position: relative;
}
.box .percent svg circle {
  width: 150px;
  height: 150px;
  fill: none;
  stroke-width: 10;
  stroke: #000;
  transform: translate(5px, 5px);
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  stroke-linecap: round;
}
.box .percent svg circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: #f3f3f3;
}
.box .percent svg circle:nth-child(2) {
  stroke-dashoffset: calc(440 - (440 * 87) / 100);
  stroke: #ffa200;
}
.box .percent .num {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #111;
}
.box .percent .num h2 {
  font-size: 30px;
}
.box .percent .num h2 span {
  font-size: 24px;
}
.box .text {
  padding: 10px 0 0;
  color: #999;
  font-weight: 700;
  letter-spacing: 1px;
}
.box img .head {
  width: 90px;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}
.projects-texture-state .container-fluid .texturepreview-right .texturezoom-preview img.expand {
  width: 100%;
  margin: 12% 0% !important;
  border-radius: 8px;
  padding: 12px !important;
  cursor: pointer;
}
.fromborder {
  border-bottom: 1px solid #ced4da !important;
  border: white !important;
}
.form-controls {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border: none;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    border: none !important;
    border-bottom: 1px solid black !important;
    border-radius: 0px !important;
    outline: none;
  }
}
#file {
  display: none;
}
.list-of-member.front {
  z-index: 99 !important;
  top: 0 !important;
}
.list-of-member {
  position: fixed;
  width: 20%;
  height: 100vh;
  padding: 1.5%;
  background-color: #fff;
  top: 62px;
  right: 0;
  transition: 1s;
  overflow-y: scroll;
  transform: translateX(0);

  .name-of-slider-in-texture {
    display: flex;
    justify-content: space-between;
  }
  .texturepreview-right {
    .info-of-list-of-slider {
      margin-top: 20px;

      .main-info-of-list-of-slider {
        border-bottom: 1px solid #c4c4c7;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        margin-top: 6%;
      }
      .main-info-of-list-of-slider1 {
        display: flex;

        .bedroom-btn {
          // width: 100px;
          // height: 40px;
          background: #ffffff;
          border: 1px solid #5a5c61;
          box-sizing: border-box;
          border-radius: 12px 12px 12px 12px;

          .bedroom-content {
            display: flex;
            // margin-top: 5px;
            justify-content: space-around;
            align-items: center;
            padding: 10px 12px;
            .bedroomclose {
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
}
.list-of-members {
  position: fixed;
  width: 23%;
  height: calc(100vh - 60px);
  padding: 1.5%;
  background-color: #fff;
  top: 59px;
  right: 0;
  transition: 1s;
  overflow-y: scroll;
  transform: translateX(0);
  z-index: 100;
  input:focus {
    border: 0px;
  }
  .name-of-slider-in-texture {
    display: flex;
    justify-content: space-between;
  }

  .texturepreview-right {
    .info-of-list-of-slider {
      margin-top: 6%;

      .main-info-of-list-of-slider {
        border-bottom: 1px solid #c4c4c7;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        margin-top: 6%;
      }

      .main-info-of-list-of-slider1 {
        display: flex;

        .bedroom-btn {
          padding: 0% 4% 0%;
          height: 40px;
          background: #ffffff;
          border: 1px solid #5a5c61;
          box-sizing: border-box;
          border-radius: 12px 12px 12px 12px;
          margin: 0 2%;

          .bedroom-content {
            display: flex;
            margin-top: 5px;
            justify-content: space-around;
          }
        }
      }
    }
  }
}

.list-of-props {
  position: fixed;
  min-width: 370px;
  height: calc(100vh - 60px);
  padding: 1.5%;
  background-color: #fff;
  width: 370px;
  top: 62px;
  right: 0;
  transition: 3s;
  overflow-y: auto;
  transform: translateX(0);
  z-index: 100;

  .name-of-slider-in-texture {
    display: flex;
    justify-content: space-between;
    .close_btn {
      padding: 10px 10px;
      background-color: grey;
    }
  }

  .texturepreview-right {
    .info-of-list-of-slider {
      margin-top: 6%;

      .main-info-of-list-of-slider {
        border-bottom: 1px solid #c4c4c7;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        margin-top: 6%;
      }

      .main-info-of-list-of-slider1 {
        display: flex;

        .bedroom-btn {
          padding: 0% 4% 0%;
          height: 40px;
          background: #ffffff;
          border: 1px solid #5a5c61;
          box-sizing: border-box;
          border-radius: 12px 12px 12px 12px;
          margin: 0 2%;

          .bedroom-content {
            display: flex;
            margin-top: 5px;
            justify-content: space-around;
          }
        }
      }
    }
  }

  .Create-vignette {
    .vignette-head {
      .sub-head {
        font-weight: 700;
        font-size: 16px;
        color: #2d3136;
      }

      .see-all {
        font-weight: 400;
        font-size: 12px;

        color: #2d3136;
      }
    }
  }
}

#createRoomModal {
  p {
    padding: 0 !important;
    margin: 0 !important;
  }

  #cbSelectAll {
    background: #e9e9e9;
  }

  label.btn:hover {
    box-shadow: none !important;
    outline: none !important;
  }

  .multi-cb {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .css-1s2u09g-control {
    background-color: #fff !important;
  }

  .modal-body {
    height: auto !important;
  }

  .btn-group label {
    // border-color: #e9e9e9;
    color: #5a5c61;
    border: none !important;
  }

  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #fff;
  }

  .btn-group > .btn-check:checked + .btn {
    color: #fff !important;
    background-color: #0059f1 !important;
    border-color: #0059f1 !important;
  }

  .modal-body-child {
    border-radius: 8px;
    padding: 6px 16px !important;
    margin: 10px 0;
    align-items: center;
    flex-direction: column;
  }

  #cameraBlock6 {
    background: #fceae8;
    border: 1px solid #e4291d;
  }

  .multi-select .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }

  .list-group {
    position: absolute;
    z-index: 999999;
  }

  .list-group-item {
    border: none !important;
  }

  .select-all-block {
    padding: 0 16px !important;
    margin: 0;
  }

  .list-group-item:hover {
    background: #e5eefe;
    cursor: pointer;
  }

  .btn {
    box-shadow: none !important;
  }

  .btn:hover {
    color: #000 !important;
    background: #ffa200 !important;
  }
  .border-black {
    border: 1px solid #000;
  }
}

.designTemplates {
  padding-bottom: 17px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 1.5em;
  &.center {
    justify-content: center;
    flex-wrap: wrap;
  }

  .template {
    flex: 0 0 180px;
    width: 180px;
    padding: 2.5em 0.75em;
    border-radius: var(--border-radius);
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    * {
      color: var(--white-color);
      text-align: center;
      line-height: 1.25;
    }
    img {
      display: block;
      margin: 0 auto 0.75em;
      max-width: 3.25em;
    }
    h5 {
      font-size: calc(var(--base-font-size) / 1.1);
      font-weight: 500;
    }
    p {
      margin-bottom: 0;
      font-size: calc(var(--base-font-size) / 1.1);
      font-weight: 400;
    }
  }
}

// Render Preview Screen
.pass-btn {
  background-color: #fff !important;
}

.pass-btn.active {
  background-color: #0d6efd !important;
}

.carousel-inner {
  height: 100%;
  min-height: 400px;
}

.preview-display-footer {
  flex: 0 0 48px;
  background: var(--black-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1.title {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.32;
    padding: 0 16px;
    color: var(--white-color);
    margin: 0;
  }

  ul.footer-action-list {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: auto;

    li {
      width: 100%;
      margin: 0 1rem;
    }
  }
}

.download-popup {
  background-color: var(--black-color);
  position: fixed;
  bottom: 60;
  left: 50%;
  // height: 128px;
  // width: 160px;
  border-radius: 8px;
  z-index: 999;
}

.render-popup {
  background-color: var(--black-color);
  position: fixed;
  min-height: 320px;
  width: 680px;
  border-radius: 8px;
  z-index: 999;
  padding: 1rem;

  div.header {
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .render-camera-picker {
    border: 1px solid var(--neutral80-color);
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 0.9rem;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
    overflow-y: scroll;
    grid-area: column1;

    div.head {
      border-bottom: 1px solid var(--neutral80-color);
    }

    div.head,
    div.list > div {
      padding: 0.7rem;
    }

    div.list > div:nth-child(2n) {
      background-color: var(--neutral80-color);
    }

    div.list div.cam-item {
      display: flex;
    }
  }

  div.render-inputs {
    font-size: 0.9rem;

    input {
      padding: 0.3rem;
      border-radius: 0.5rem;
    }

    input[type='number'] {
      border: none;
      background-color: var(--neutral80-color);
      color: #fff;
    }

    div.styled-checkbox {
      border: none;
      background-color: var(--neutral80-color);
      &.selectAll {
        background-color: white;
      }
    }

    div.styled-checkbox.checked {
      background-color: var(--secondary-color);
    }
  }
}

.action-filters {
  display: flex;
  // margin: 0 1.5rem;
  margin-bottom: 1.5rem;
  justify-content: space-between;

  .af-left {
    display: flex;
  }

  .af-right {
    display: flex;
  }
}

.team-members-offcanvas {
  top: 75px !important;
  width: 350px !important;

  .tm-actions {
    display: flex;
    justify-content: space-between;
  }

  .tm-list {
    padding: 0;

    .tm-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #e5eefe;
      border-radius: 8px;
      padding: 16px 48px 16px 16px;
      margin: 8px 0px;

      .tm-item-content {
        display: flex;
        align-items: center;
        padding: 5px;

        & > img {
          margin: 0 1rem 0 0;
        }
      }
    }
  }
}

.sectionContentContainer {
  padding: 0 18px 30px;
}

.cardContainer {
  .scrollableContent {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  :global(.thumbnail-card) {
    width: calc(calc(100% - 8px) / 2);
  }
}

.searchIcon,
.filterButton {
  margin-left: 10px;
  position: absolute;
  display: grid;
  align-items: center;
  height: 100%;
}
.searchIcon {
  left: 10px;
}

.filterButton {
  right: 10px;
  color: #5a5c61;
  cursor: pointer;
  width: 24px;
  height: 24px;
  top: 0;
  bottom: 0;
  margin: auto 0;

  &.filterButtonActive,
  &:hover {
    background: #0059f1;
    border-radius: 6px;
    color: #fff;
  }

  img {
    margin-bottom: 0;
  }
}

.selectedFilter {
  padding: 0 5px 0 8px;
  height: 32px;
  border: 1px solid #a3a4a6;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  line-height: 1.3;
  display: inline-flex;
  align-items: center;

  .deleteButton {
    color: #a3a4a6;
    margin-left: 2px;
    padding: 3px;

    &:hover {
      cursor: pointer;
      color: #fff;
    }
  }
}

.top-buttons > button.btn {
  margin-left: 5px;
  margin-right: 5px;
}

.filterDropdown {
  width: 100% !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  overflow: visible !important;
}

div.feature-nav {
  width: 100% !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  overflow: visible !important;
  z-index: 15 !important;
}

.modal {
  & > .new-project {
    max-width: 888px;
  }
}

.top-buttons > button.btn {
  margin-left: 5px;
  margin-right: 5px;
}

.selectedSideNav {
  background-color: purple;
}

.filterCloseBtn {
  border: 1px solid #5a5c61;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 12px 12px 12px 12px;
}

.modal-dialog.new-room {
  min-width: 35vw;
  max-width: 85vw;
}

.lfSelect__menu {
  z-index: 1000 !important;
}

.userName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nonVisibleBackdrop.offcanvas-backdrop.show {
  opacity: 0;
}

button.modalCloseButton {
  width: 25px;
  height: 25px;
  border-radius: 10px;
  border-color: var(--neutral20-color);
  background-color: var(--neutral20-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  color: var(--neutral80-color);
  &:hover {
    background-color: var(--neutral40-color);
    border-color: var(--neutral40-color);
    color: var(--neutral90-color);
  }
}

button.sidebarCloseButton {
  width: 25px;
  height: 25px;
  border-radius: 10px;
  border-color: var(--neutral20-color);
  background-color: var(--neutral20-color);
  color: var(--neutral80-color);
  padding: 1em;
  justify-content: center;
  & > svg {
    position: relative;
    right: 5px;
    top: -12px;
    max-width: 10px;
  }
  &:hover {
    background-color: var(--neutral40-color);
    border-color: var(--neutral40-color);
    color: var(--neutral90-color);
  }
}

button.footerCloseButton {
  width: 25px;
  height: 25px;
  border-radius: 10px;
  border-color: var(--neutral80-color);
  background-color: var(--neutral80-color);
  align-self: flex-end;
  margin-top: -15px;
  & > svg {
    position: relative;
    right: 5px;
    top: -10px;
    max-width: 9px;
  }
}

.modal-content {
  padding: 1rem;
  border-radius: 0.5rem;
}

.modal-header.commonPopupHeader {
  border-bottom: 0px;
}
.modal-footer.commonPopupFooter {
  border-top: 0px;
  justify-content: space-evenly;

  button {
    padding: 0.7rem 3rem;
    border-radius: 0.5rem;
  }
}

.offcanvas-header.sidebarHeader {
  background-color: var(--neutral10-color);
}

.breadcrumbHeader {
  height: 64px;
  background-color: white;
  padding-left: 2em;
  align-items: center;
  & > nav {
    padding-top: 1.25em;
  }
}

.selected-product {
  border-bottom: 4px blue solid;
  border-radius: 5px;
  color: blue;
}

.interest-btn {
  background-color: #f6f6f6 !important;
  border: 1px solid black !important;
  color: black !important;
  margin-right: 1.5em;
  box-shadow: none !important;
}

.invite-btn {
  color: var(--black-color);
  background-color: var(--primary-color);
  border: 0;
  border-radius: 0.5rem;
  float: right;
  padding: 0.6rem 1rem;

  &:hover {
    color: var(--black-color);
    background-color: var(--primary-color);
  }
}

.light-btn {
  color: var(--black-color);
  background-color: var(--light-yellow);
  border: 0;
  border-radius: 0.5rem;
  float: right;
  padding: 0.6rem 1rem;

  &:hover {
    color: var(--black-color);
    background-color: var(--light-yellow);
  }
}

.product-select {
  font-weight: 700;
}

.thumbnail-info.bottomStatusBar {
  &Blue {
    -webkit-box-shadow: inset 0px -16px 0px -9px var(--render-selected) !important;
    -moz-box-shadow: inset 0px -16px 0px -9px var(--render-selected) !important;
    box-shadow: inset 0px -16px 0px -9px var(--render-selected) !important;
  }
  &Red {
    -webkit-box-shadow: inset 0px -16px 0px -9px var(--render-failed) !important;
    -moz-box-shadow: inset 0px -16px 0px -9px var(--render-failed) !important;
    box-shadow: inset 0px -16px 0px -9px var(--render-failed) !important;
  }
  &Green {
    -webkit-box-shadow: inset 0px -16px 0px -9px var(--render-done) !important;
    -moz-box-shadow: inset 0px -16px 0px -9px var(--render-done) !important;
    box-shadow: inset 0px -16px 0px -9px var(--render-done) !important;
  }
}

.selectableTable {
  border: 1px solid var(--neutral40-color);
}

.selectableThead > .selectableRow > th {
  border-bottom: 1px solid var(--neutral40-color);
}

.selectableRow {
  height: 72px !important;
  max-height: 72px !important;
  & > td,
  > th {
    height: 72px !important;
    max-height: 72px !important;
    vertical-align: middle !important;
    border-bottom: none;
    border-right: 1px solid var(--neutral20-color) !important;
    border-left: 1px solid var(--neutral20-color) !important;
  }
}

thead > .selectableRow {
  border-top: 1px solid var(--neutral20-color) !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.selectableTable.table > :not(:first-child) {
  border-top: none !important;
}

.selectableTable.table > :not(:last-child) > :last-child > * {
  border-bottom: none !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

input.selectableCheckbox {
  display: inline-block;
  min-height: 16px !important;
}

.selectIconDiv {
  width: 32px !important;
}
.selectableTable {
  height: 33vh !important;
  overflow-y: auto;
  border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  table {
    border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    border-collapse: separate;
    border-spacing: 0;
    border-top: 1px solid white;
  }
}
.selectableThead {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  tr,
  th {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &All {
    tr,
    th {
      font-weight: normal;
    }
  }
}

.selectableTableBody {
  max-height: calc(33vh - 74px) !important;
  overflow-y: scroll;
  position: relative;
  border-top: 1px solid var(--neutral20-color) !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.sidebarNav {
  min-height: 50px !important;
  &.active {
    & > a,
    > svg,
    > img {
      color: blue !important;
      & > img {
        color: blue !important;
        fill: blue !important;
      }
    }
  }
}

.stackedCarousel {
  // min-height: 450px !important;
  width: 85% !important;
  overflow-x: hidden;
  max-height: 225px !important;
  overflow-y: hidden;
}
.collectionCardCount {
  position: absolute;
  z-index: 45;
  bottom: 3em;
  color: white;
  font-size: large;
  text-align: center;
  align-self: center;
  width: 100%;
  font-weight: 800;
}

.greyCard {
  &0 {
    background-color: var(--neutral40-color);
  }
  &1 {
    background-color: var(--neutral80-color);
  }
  &2 {
    background-color: var(--neutral60-color);
  }
}

// Thumbnail Card

//.card-grid{
// width: 100%;
// display: flex;
// justify-content: flex-start;
// align-items: flex-start;
// flex-wrap: wrap;
// gap: 15px;
// overflow: hidden;
//}
.thumbnail-card {
  width: 100%;
  // max-width: var(--thumbnail-width);
  // display: flex;
  // flex-direction: column;
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  background-color: var(--neutral20-color);
  overflow: hidden;

  * {
    margin: 0;
    padding: 0;
    font-family: var(--font-family) !important;
  }
}
.thumbnail-img {
  position: relative;
  overflow: hidden;

  figure {
    width: 100%;
    border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    overflow: hidden;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      object-position: center;
      object-fit: cover;
    }
  }
}
.thumbnail-action {
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  left: 0;
}

.thumbnail-card {
  ul {
    position: relative;
    width: 100%;
    padding: 1em;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    z-index: 100;
    &.collectionButtons {      
      justify-content: space-around !important;
    }

    li {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;

      label {
        position: absolute;
        cursor: pointer;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        border: none;
        border-radius: var(--card-actions-border-radius);
        -moz-border-radius: var(--card-actions-border-radius);
        background-color: var(--white-color);
      }
      label:after {
        position: absolute;
        content: '';
        width: 10px;
        height: 5px;
        top: 6px;
        left: 5px;
        opacity: 0;
        transform: rotate(-45deg);
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
      }
      .favouriteCard + label {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: auto;
        right: 0;
        background-color: var(--favourite-bg-color);
      }
      input[type='checkbox'] {
        display: block;
        width: 15px;
        height: 15px;
        visibility: hidden;
      }
      .selectCard:checked + label {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
      }
      .selectCard:checked + label:after {
        opacity: 1;
      }
      .favouriteCard + label:after {
        display: none;
      }
      .favouriteCard:checked + label {
        background-color: var(--favourite-bg-color);
        border-color: var(--favourite-bg-color);
      }
      .favouriteCard:checked + label:after {
        opacity: 1;
      }
    }
  }
}
.thumbnail-tag {
  position: absolute;
  width: calc(100% - 2em);
  padding: 1em 1em 0.75em;
  left: 0;
  bottom: 0;

  .tag {
    margin: 0 0.5em 0.5em 0;
    padding: 0.25em 0.65em;
    font-size: 0.62em;
    color: var(--black-color);
    display: inline-block;
    border-radius: 4px;
    background-color: var(--white-color);
  }
}
.thumbnail-info {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 1em;

  .inner-info {
    flex: 0 0 calc(100% - 30px);
    width: calc(100% - 30px);
  }
  .inner-action {
    flex: 0 0 30px;
    width: 30px;
  }
  .thumbnail-title {
    margin-bottom: 0.2em;
    color: var(--black-color);
    font-size: 0.75em;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .thumbnail-etime {
    color: var(--neutral80-color);
    font-size: 0.5em;
    font-weight: 400;
  }
  .inner-action {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    a {
      position: relative;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 20px;
      height: 16px;
      color: var(--black-color);
      font-size: 12px;

      span {
        display: block;
        width: 3px;
        height: 3px;
        background-color: var(--black-color);
        border-radius: 50%;

        &:before,
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 3px;
          height: 3px;
          left: 50%;
          background-color: var(--black-color);
          border-radius: 50%;
          transform: translateX(-50%);
        }
        &:before {
          top: 0;
        }
        &:after {
          bottom: 0;
        }
      }
    }

    ul {
      position: absolute;
      top: -50px;
      left: 0;
      border-radius: var(--card-actions-border-radius);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 1px solid var(--white-color);
      background-color: var(--neutral20-color);

      li {
        img {
          width: 100%;
          max-width: 15px;
        }
      }
    }
  }
}

// responsiveness

// @media only screen and (max-width: 576px){
//     .card-grid > div{
//         width: calc(100% - 15px);
//         flex: 0 0 calc(100% - 15px);
//     }
// }
// @media only screen and (min-width: 577px) and (max-width: 767px){
//     .card-grid > div{
//         width: calc(50% - 15px);
//         flex: 0 0 calc(50% - 15px);
//     }
// }
// @media only screen and (min-width: 768px) and (max-width: 1024px){
//     .card-grid > div{
//         width: calc(33.33% - 15px);
//         flex: 0 0 calc(33.33% - 15px);
//     }
// }
// @media only screen and (min-width: 1025px){
//     .card-grid > div{
//         width: calc(25% - 15px);
//         flex: 0 0 calc(25% - 15px);
//     }
// }

// Cards
.selectimg-footer {
  background-color: transparent !important;
}

.selected-card {
  .selectimg-footer {
    color: var(--secondary-color) !important;
  }
  .selectimg-footer:after {
    content: '';
    background-color: var(--secondary-color);
    position: absolute;
    width: 95%;
    height: 0.4rem;
    bottom: 1px;
    left: 0.5rem;
    border-bottom-right-radius: 6rem;
    border-bottom-left-radius: 6rem;
  }
}
.selectimg-card {
  border: 0 !important;
}
// border-shadow:1px -12px 0px -7px rgb(69 80 248) inset

.chip {
  display: inline-block;
  padding: 0 10px;
  height: 35px;
  font-size: 16px;
  line-height: 35px;
  border-radius: 8px;
  background-color: var(--background-color);
  border: 1px solid var(--neutral80-color);
}

.chip img {
  float: left;
  margin: 0 10px 0 -25px;
  height: 50px;
  width: 50px;
  border-radius: 20%;
}

.closebtn {
  padding: 0 4px;
  color: var(--neutral80-color);
  font-weight: bold;
  // float: right;
  font-size: small;
  cursor: pointer;
  border: 1px solid var(--neutral80-color);
  border-radius: 50%;
  margin-left: 12px;
  vertical-align: top;
}

.empty_trash {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  button {
    padding: 0.65em 1.25em;
    background-color: var(--white);
    border: 1px solid var(--neutral80-color);
    overflow: hidden;
    border-radius: var(--border-radius);
    vertical-align: middle;

    span {
      display: inline-block;
      color: var(--neutral80-color);
      font-weight: 300;
      font-size: calc(var(--base-font-size) / 1.13);
      line-height: 2;
    }

    img {
      vertical-align: middle;
      display: inline-block;
    }
  }
}
.filter_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  // gap: 1em;

  .layout,
  .filter {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    overflow: hidden;

    button {
      padding: 0.25em 1.25em;
      border-radius: 0;
      background-color: var(--white);
      box-shadow: none;
      overflow: hidden;
      border: 1px solid var(--neutral20-color);
    }
    button.active {
      background-color: var(--secondary-color);
    }
    .btn-table {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
    .btn-grid {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }

  .card-arrow::before {
    top: 20px;
    right: 68px;
    transform: rotate(45deg);
    background-color: #111111;
    border-color: #111111;
  }

  .sharedByMembers {
    .userSelectionBtn {
      padding: 0;
      width: 40px;
      height: 40px;
      border: none;
      border-radius: var(--border-radius);
    }
    .allUsers,
    .fs-by-user.div {
      color: var(--white-color);
      background-color: var(--secondary-color);
    }
    .userSelectionBtn.multiple {
      width: 80px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .fs-by-user {
        display: block;
        flex: 0 0 40px;
      }
      .fs-by-user.div {
        text-align: center;
        line-height: 40px;
      }
      .fs-by-user:not(:nth-child(1)) {
        width: 42px;
        height: 44px;
        border-radius: var(--border-radius);
        border: 2px solid var(--white-color);
        border-right-width: 0;
      }
      .fs-by-user:nth-child(1) {
        z-index: 10;
        transform: translate(0);
        -webkit-transform: translate(0);
      }
      .fs-by-user:nth-child(2) {
        transform: translate(-22px, -2px);
        -webkit-transform: translate(-22px, -2px);
        z-index: 20;
      }
      .fs-by-user:nth-child(3) {
        transform: translate(-42px, -2px);
        -webkit-transform: translate(-42px, -2px);
        z-index: 30;
      }
    }

    .list {
      position: absolute;
      min-height: 50px;
      max-height: 500px;
      width: var(--thumbnail-width);
      top: 0;
      right: auto;
      left: 100px;
      border: 1px solid #111111;
      border-radius: var(--border-radius);
      z-index: 10000;
      overflow-x: hidden;
      overflow-y: auto;
      box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.25);

      li {
        margin: 0;
        padding: 0.5em;
        border: none;
      }
      li:nth-child(2n + 1) {
        background-color: var(--white-color);
      }
      li:nth-child(2n) {
        background-color: var(--neutral10-color);
      }

      .card {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5em;
        border: none;
        border-radius: 0;
        background-color: transparent;

        * {
          margin: 0;
          padding: 0;
          border: none !important;
          border-radius: 0 !important;
        }

        figure {
          flex: 0 0 40px;
          width: 40px;
          height: 40px;
          border-radius: var(--border-radius);
          overflow: hidden;
        }
        .card-body {
          flex: 0 0 calc(100% - 40px - 0.5em);
          width: calc(100% - 40px - 0.5em);
        }

        .card-title {
          margin-bottom: 0.25em;
          text-transform: capitalize;
          font-size: calc(var(--base-font-size) / 1.15);
          font-weight: 400;
          color: var(--black-color);
          line-height: 1.25;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .card-text {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          gap: 1em;

          span {
            font-size: calc(var(--base-font-size) / 1.45);
            color: var(--neutral80-color);
            line-height: 1.25;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    // .list:not(.active){
    //     display: none;
    // }
  }

  button,
  .dropdown select {
    height: 40px;
  }

  .dropdown {
    border: 1px solid var(--neutral20-color);
  }
  .dropdown select {
    appearance: none;
    width: 175px;
    padding: 0 0.5em;
    font-size: calc(var(--base-font-size) / 1.125);
    background-color: var(--white-color) !important;
    background-image: url('../../assets/images/select-arrow.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 1em);
    border: none;
  }
  .dropdown select,
  .dropdown select option {
    color: var(--neutral80-color);
  }
}

.filter_container.selection_2 {
  height: 40px;
  justify-content: flex-end;
  gap: 0.5em;

  .filter:not(.shared_by) {
    border-radius: 0;
  }

  label {
    font-size: calc(var(--base-font-size) / 1.1);
    color: var(--black-color);
  }
}
.filter-sidebar-wrapper {
  overflow: visible;
}
.filter_container.sidebar-filter {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;

  .filter-col {
    position: relative;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    overflow: visible !important;

    > * {
      width: 100%;
      flex: 0 0 100%;
    }

    .nav-selection {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;

      .filter {
        margin-right: 0.5em;

        .fc-icon {
          margin: 0;
          padding: 0;
          width: 20px;
          height: 20px;
        }
      }
      .nav-link {
        color: var(--black-color);
      }
    }

    .checkbox-selection .selectCard + label {
      background-color: var(--neutral20-color);
      border-color: var(--neutral20-color);
    }
    .collection.checkbox-selection .selectCard:not(:checked) + label {
      display: flex;
      background-color: transparent;
    }
    .collection.checkbox-selection .selectCard:checked + label > img {
      visibility: hidden !important;
    }

    .head-h5 {
      font-size: calc(var(--base-font-size) / 1.05);
      font-weight: 600;
    }
  }

  .filter-col:not(:last-child) {
    &::after {
      position: absolute;
      content: '';
      width: calc(100% + 1.5em);
      height: 0;
      left: -1.5em;
      bottom: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
}

// shared by members: sidebar
.files-shared-with-users-container.sidebar {
  // padding: 1.5em 0.75em;
  left: auto !important;
  right: 0;
  top: 67px;
  width: 232px;
  overflow-y: initial;
  overflow-x: visible;
  transition: transform 300ms ease;
  -webkit-transition: transform 300ms ease;
  // transform: translateX(var(--sidebar-width));
  // -webkit-transform: translateX(var(--sidebar-width));

  .closeFSUsers {
    position: absolute;
    margin: 0;
    padding: 0;
    top: -12px;
    left: -12px;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 8px;
    background-color: var(--neutral20-color);
    color: var(--neutral80-color);
    // transform: translate(-50%, -50%);
    // -webkit-transform: translate(-50%, -50%);
    z-index: 1000000 !important;
    overflow: visible;

    & > svg {
      //   position: absolute;
      //   right: 27px;
      //   top: 24px;
      max-width: 8px;
    }
    &:hover {
      cursor: pointer;
      background: rgba(45, 49, 54, 0.8);
      color: white;
    }
  }

  .fs-container {
    margin: 0 0 0.25em;
    padding: 0.5em 0;
    display: block;
    width: 100%;

    .head-h5 {
      margin: 0;
      padding: 0.5em 0.75em 1.25em;
      font-size: calc(var(--base-font-size) / 1.25);
      font-weight: 500;
    }

    .user-list {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      * {
        margin: 0;
        padding: 0;
      }

      li {
        cursor: pointer;
        margin-bottom: 0.5em;
        padding: 0.75em;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        gap: 0.75em;
        border-radius: var(--border-radius);
      }

      figure {
        width: 30px;
        flex: 0 1 30px;
        overflow: hidden;

        img {
          width: 100%;
          max-width: 30px;
          border-radius: var(--border-radius);
        }
      }
      .user-info {
        width: calc(100% - 50px - 0.75em);
        flex: 0 1 calc(100% - 50px - 0.75em);

        .user-name {
          color: var(--black-color);
          font-size: calc(var(--base-font-size) / 1.109);
          font-weight: 400;
          line-height: 1.1;
        }
        .user-org {
          margin-top: 0.35em;
          color: var(--black-color);
          font-size: calc(var(--base-font-size) / 1.25);
          font-weight: 400;
          line-height: 1.1;
        }
      }
    }
  }

  .search-members {
    padding-bottom: 2.25em;
    // border-bottom: 1px solid var(--neutral40-color);
    border-bottom: 1px solid #c4c4c7;
  }

  .inside.members {
    .all-user-select {
      // margin-bottom: 2em;
      padding: 0.5em 0.75em 1.25em;
      display: flex;
      gap: 0.5em;
      height: 64px;
      align-items: center;

      input:not(:checked) + label {
        background-color: var(--background-color);
      }

      .selectionCount {
        margin-left: auto;
        color: var(--neutral80-color);
      }
    }
  }

  .outside.members {
    ul {
      li {
        background-color: var(--light-grey);
      }
    }
  }
  .members {
    ul {
      li.selected {
        background-color: var(--light-blue);
      }
    }
  }
}

// head-h5
.head-h5 {
  font-size: calc(var(--base-font-size) / 0.8);
  font-weight: 400;
  color: var(--black-color);
}
.see_all_link {
  font-size: calc(var(--base-font-size) / 1.05);
  font-weight: 400;
  color: var(--secondary-color);
}

// card carousel
.carousel-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  // .head-h5{

  // }
}
.slick-slider {
  margin: 0 -15px;

  .slick-track {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  .slick-slide {
    width: calc(var(--thumbnail-width) + 20px) !important;
    padding: 10px;
  }

  .slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.15em;
    height: 2.15em;
    font-size: inherit;
    right: 0;
    z-index: 1000;
    border-radius: 50%;
    background-color: var(--white-color);

    &:hover,
    &:focus {
      background-color: var(--white-color);
    }
  }
  .slick-prev {
    left: -6px;
  }
  .slick-next {
    right: 10px;
    transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
  }
  .slick-arrow::before {
    position: absolute;
    content: '';
    width: 0.65em;
    height: 0.65em;
    font-family: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .slick-prev::before {
    left: 0;
    transform: translate(75%, -50%) rotate(-180deg);
    -webkit-transform: translate(75%, -50%) rotate(-180deg);
    background-image: url('../images/right-arrow.svg');
  }
  .slick-next::before {
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    background-image: url('../images/right-arrow.svg');
  }
}

.top-buttons {
  .btn-secondary {
    background-color: var(--neutral20-color);
    color: var(--black-color);
    border: none;

    &:hover {
      background-color: var(--neutral40-color);
    }
  }
}

.styled-checkbox {
  width: 24px;
  height: 24px;
  background: #e9e9e9;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  &.selectAll {
    background-color: white;
  }
  & > input[type='checkbox']:before {
    background: #e9e9e9;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    width: 24px;
    height: 24px;
  }
}

.pretty.styled-checkbox {
  width: 24px;
  height: 24px;
  background: #e9e9e9;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  display: -webkit-inline-box;
  &.selectAll {
    background-color: white;
  }
  & > input[type='checkbox']:checked {
    visibility: hidden !important;
    border-radius: 8px;
  }
  & > img,
  svg {
    position: relative;
    top: -1px;
    left: -26px;
    color: white !important;
    border-radius: 8px;
  }
}

.pretty.styled-checkbox input[type='checkbox'] {
  visibility: hidden !important;
  border-radius: 8px;
}

.pretty.styled-checkbox > input[type='checkbox']:checked:before {
  width: 24px;
  height: 24px;
  background: var(--secondary-color);
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  &.selectAll {
    background-color: white;
  }
}
.pretty.styled-checkbox.checked,
.pretty.styled-checkbox.checked input[type='checkbox'] {
  width: 24px;
  height: 24px;
  background: var(--secondary-color);
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  // margin-top: -24px !important;
}

// Settings screen
.mySettings {
  .btn-success {
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    color: black !important;
    box-shadow: none !important;
  }

  // .btn-secondary{
  //     color: black !important;
  //     background-color: #E9E9E9 !important;
  //     border-color: #E9E9E9 !important ;
  //     float: right;
  //     border: none !important;
  //     width: 6.125em !important;
  //     height: 3em;
  //     margin-top: -1.6em;
  // }

  .select-preference {
    float: left;
  }
  .cancel {
    float: right;
  }

  .curator-option {
    background-color: #f9f9f9;
  }
  .graphic-quality {
    border-radius: 5px;
    width: 560px;
    height: 128px;
    background: #ffffff;
    border-radius: 8px;
    margin: 10px;
  }

  .color-theme {
    margin: 10px;
    width: 560px;
    height: 200px;
    left: 256px;
    top: 376px;
    background: #ffffff;
    border-radius: 8px;
  }

  .application-setup {
    margin: 10px;
    width: 560px;
    height: 120px;
    background: #ffffff;
    border-radius: 8px;
  }

  .VH-Speed {
    margin: 10px;
    width: 560px;
    height: 176px;
    background: #ffffff;
    border-radius: 8px;
  }

  .user-drive {
    margin: 10px;
    width: 560px;
    height: 152px;
    background: #ffffff;
    border-radius: 8px;
  }

  .quality-btn {
    width: 160px;
    height: 40px;
    margin: 15px;
    margin-top: 40px;
  }

  .left-section {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f6f6f6;
    // padding: 1em;
  }

  .submit-btn {
    background-color: #ffa200 !important;
    border-color: #ffa200 !important;
    border-radius: 8px;
    width: 344px;
    height: 48px;
    margin: 30px;
  }

  .modal-editpassword {
    .modal-content {
      width: 440px !important;
      height: 576px !important;
      background-color: #f9f9f9 !important;
    }
  }

  .modal-changeowner {
    .modal-content {
      display: flex;
      flex-direction: column;

      padding: 16px 24px 48px;

      width: 690px;
      height: 296px;

      /* Brand colors/Others/White */

      background: #ffffff;
      border-radius: 8px;
    }
  }

  .modal-chooseowner {
    text-align: center;

    .modal-content {
      display: flex;
      flex-direction: column;

      padding: 16px 24px 48px;

      width: 650px;
      height: 564px;

      /* Brand colors/Others/White */

      background: #ffffff;
      border-radius: 8px;
    }

    .cancel-icon {
      position: absolute;
      right: 66px;
      top: 88px;
    }

    h1 {
      font-size: 32px;
      line-height: 124%;
    }

    .search-input {
      width: 554px;
      height: 48px;
      left: 0px;
      top: 0px;

      /* Brand colors/Others/White */

      background: #ffffff;
      /* Brand colors/Others/Black */

      border: 1px solid #2d3136;
      box-sizing: border-box;
      border-radius: 8px;

      /* Inside auto layout */

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      margin: 8px 0px;
    }

    .filter-section {
      height: 288px;

      /* Brand colors/Others/White */

      background: #ffffff;
      /* Brand colors/Others/Black */

      border: 1px solid #2d3136;
      box-sizing: border-box;
      margin: 10px;
      border-radius: 8px;

      .filter-section-props {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;

        position: static;
        width: 546px;
        height: 72px;
        left: 0px;
        top: 0px;
        border-radius: 10px;
        /* Neutral/10 */

        background: #f9f9f9;
      }

      .props-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;

        position: static;
        width: 152px;
        height: 40px;
        left: 16px;
        top: 16px;

        .props-data {
          display: flex;
          flex-direction: column;
          text-align: left;
          margin-left: 10px;

          span {
            font-size: 12px;
            line-height: 130%;
            /* identical to box height, or 16px */

            /* Neutral/80 */
          }
        }
      }
    }
  }

  // .details-page {
  //     padding: 3%;
  // }
  .details-page {
    figure {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .dropdown-parent {
      flex-wrap: nowrap;
      .details-info-part {
        width: 100%;
        .dropdown {
          width: 100%;
        }
      }
      .dropdown {
        width: 100%;
      }
    }
  }

  .forgotpassword {
    float: right;
    margin-right: 2em;
    margin-top: 27px;
  }

  .new-password {
    margin-top: 65px;
  }

  .confirm-password {
    margin-top: 35px;
  }

  .contact-support {
    // float: right;
    color: #0059f1;
    cursor: pointer;
    // margin-top: -0.5em;
    display: flex;
    align-items: center;
    max-height: 19px !important;
    img {
      margin-right: 12px;
    }
  }

  .input-name {
    width: 19.8125em !important;
    height: 3em;
    border-radius: 0.5em;
  }

  .cancel-btn {
    background-color: #f6f6f6 !important;
    color: #000000 !important;
    width: 6.3875em;
    height: 3em;
    border-color: #5a5c61 !important;
    padding: 0.75em 1em;
  }

  .save-btn {
    background-color: #ffa200 !important;
    color: #000000 !important;
    width: 6.3875em;
    height: 3em;
    border-color: #ffa200 !important;
    padding: 0.75em 1em;
    margin-left: 1em;
  }

  .toggle-switch {
    float: right;
    margin: 10px;
  }

  .btn-primary:focus {
    box-shadow: none !important;
  }

  .notification-type {
    text-align: right;
    font-size: 12px;
    margin-bottom: 10px;
    margin-right: 1em;

    span {
      color: var(--neutral80-color);
    }
    .notification-email-label {
      margin-right: 2.063rem;
    }
  }

  .transaction-top {
    border: 1px solid #e9e9e9;
    height: 104px;
    width: 365px !important;
    padding: 16px !important;
    margin-left: none !important;
    border-radius: 8px;
    margin-right: 25px;
    margin-bottom: 25px;
    background-color: var(--white-color);
  }

  .add-credits {
    background-color: #ffa200 !important;
    border-color: #ffa200 !important;
    float: right;
    padding: 12px, 22px, 12px, 16px;
    width: 152px;
    height: 48px;
    margin-top: -52px;
  }

  .addcredit-cont {
    background: #e9e9e9;
  }

  input[type='text'] {
    display: inline-block;
  }

  // .save-switch{
  //     width: 320px;
  //     height: 48px;
  // }

  .input-section1 {
    display: flex;
  }

  .eye-icon-settings {
    position: relative;
    float: right;
    margin-top: -1.25em;
    margin-right: 3em;
  }

  .right-section .card {
    background-color: #f6f6f6 !important;
  }

  .back-btn {
    background-color: #5a5c61 !important;
    border: none !important;
  }

  .notif-container {
    width: 50vw;
    margin-left: 1em;
  }

  .setting-tabs {
    ul {
      justify-content: space-evenly !important;
    }
    .nav-link {
      color: #5a5c61 !important;
      font-size: 1em !important;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      color: #0059f1 !important;
      font-size: 1em !important;
      // border-bottom: 3px #0059F1  solid !important ;
      position: relative;
      padding-bottom: 1em;
    }

    .nav-tabs .nav-link:after,
    .nav-tabs .nav-item .nav-link:after {
      content: '';
      height: 3px;
      width: 175%;
      background: transparent;
      position: absolute;
      left: 50%;
      border-radius: 2px;
      transform: translate(-50%, 0);
      bottom: 0;
    }

    .nav-tabs .nav-link.active:after,
    .nav-tabs .nav-item.show .nav-link:after {
      content: '';
      height: 3px;
      width: 175%;
      background: #0059f1;
      position: absolute;
      left: 50%;
      border-radius: 2px;
      transform: translate(-50%, 0);
      bottom: 0;
    }

    .nav-tabs {
      border-bottom: none !important;
      background-color: #ffffff !important;

      .nav-item {
        button {
          padding: 20px 0;
          font-weight: 700;
        }
      }
    }

    .nav-link {
      // width: 19vw!important;
      border: none !important;
    }
  }

  .detail-label {
    font-size: 0.875em;
    color: #5a5c61;
  }

  .main-label {
    color: #000000;
    margin-bottom: 0.5em;
  }

  .details-info-part {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .detail-info {
      font-size: 1em;
      color: #2d3136;
    }

    .btn-secondary {
      color: black !important;
      background-color: #e9e9e9 !important;
      border-color: #e9e9e9 !important;
      border: none !important;
      width: auto !important;
      height: auto;
      margin-top: 0;
      align-items: center;
      padding: 12px 20px;
    }
  }

  .tab-content {
    background-color: #f6f6f6;
    padding: 0 1.5rem;
  }

  .detail-links {
    color: #0059f1;
    cursor: pointer;
  }

  .email-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-check:focus + .btn-secondary,
  .btn-secondary:focus {
    box-shadow: none !important;
  }

  .greyEditBtn {
    width: auto;
    float: right;
    color: black !important;
    background-color: #e9e9e9 !important;
    border-color: #e9e9e9 !important;
    border: none !important;
    height: auto !important;
    margin-top: 0;
    padding: 12px 22px 12px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }

  .notif-table td {
    height: 5em !important;
  }

  // .right-section {
  //     padding: 1em;
  // }

  .dropdown-input {
    height: 3em;
  }

  .dropdown-parent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
  }

  .dropdown-menu {
    width: calc(100em - 85em);
  }

  .org-details {
    background-color: none !important;
    border: none !important;
  }

  .password-input {
    width: 21.5em !important;
    height: 3em;
    color: #5a5c61 !important;
    background-color: #e9e9e9 !important;
  }

  .settings-modal {
    .password-field {
      margin-left: 2em;
    }
  }

  .input-div {
    float: left;
  }

  .btn-div {
    float: right;
  }

  .adjust-div {
    clear: both;
  }

  .table > :not(caption) > * > * {
    // background-color: #f9f9f9 !important;
    background-color: #f9f9f9;
  }

  .table-striped > tbody > tr:nth-of-type(odd) > * {
    height: 80px !important;
    background-color: #ffffff !important;
  }

  .table-bordered > :not(caption) > * > * {
    height: 80px !important;
    // background-color: #f9f9f9 !important;
    background-color: #f9f9f9;
  }

  .notif-switch {
    margin-top: 0.5em;
  }

  .notif-style {
    float: left;
    margin-top: 0.75em;
  }

  // .css-g1d714-ValueContainer {
  //     max-height: 3em;
  //     overflow: auto !important;
  // }

  // .css-g1d714-ValueContainer>div {
  //     width: 140px !important;
  // }

  .css-1s2u09g-control {
    height: 3em !important;
    border-radius: 8px !important;
  }
  .css-1pahdxg-control,
  .css-1pahdxg-control:hover {
    height: 3em !important;
    border-color: #e9e9e9 !important;
    border-radius: 8px !important;
  }

  // #uncontrolled-tab-example-tab-notification, #uncontrolled-tab-example-tabpane-details, #uncontrolled-tab-example-tab-preference, #uncontrolled-tab-example-tab-transactionHis{
  //     padding-top: 1em;
  // }

  .input-border {
    padding-bottom: 0.5em;
    border-bottom: 1px solid #e9e9e9;
    padding-top: 1.5em;
    button.greyEditBtn {
      margin-top: -20px;
    }
  }
  .notif-container {
    .notif-heading {
      padding-top: 1.5em;
      padding-bottom: 0.5em;

      h1 {
        font-size: 2rem;
        margin-bottom: 0;
        color: var(--black-color);
      }
    }
    p {
      padding-bottom: 0;
      color: var(--neutral80-color);
    }
  }

  .table-layout {
    border: 1px solid #e9e9e9;
    overflow: hidden;
    border-radius: 8px !important;
  }

  .table-layout .table {
    margin: 0 !important;
    border: none !important;
  }

  .table-layout .table tr td {
    border-bottom: none;
    height: 80px !important;
    padding-left: 1em;
  }

  .org-info {
    margin-top: 3px;
  }

  // .css-6j8wv5-Input {
  //     height: 2em !important;
  // }

  .industry-edit {
    float: right;
    margin-top: 1em !important;
  }

  // .css-g1d714-ValueContainer {
  //     overflow-x: auto;
  // }

  .idustry-parent {
    display: flex;
  }

  .parent-row {
    display: flex;
  }

  .flex-container {
    display: flex;

    // div {
    //     flex: 1
    // }
  }

  .top-mar {
    margin-top: 0.75em;
  }

  .notif-row {
    line-height: 2.5em;
    padding-top: 1.25em !important;
  }

  .savedDetails-cont {
    width: 519px !important;
    height: 88px;
    border-radius: 8px;
    background-color: #2d3136;
    color: white;
    position: absolute;
    left: calc(50% - 519px / 2);
    padding: 16px;
    bottom: 0;
  }

  .table > :not(caption) > * > * {
    box-shadow: none !important;
  }

  // .css-tj5bde-Svg {
  //     border: 1px solid #000000 !important;
  //     border-radius: 8px;
  // }

  // .css-1rhbuit-multiValue {
  //     background-color: white !important;
  //     border: 1px solid #26282F !important;
  //     border-radius: 8px !important;
  //     margin: 5px !important;
  //     height: 2.15em;
  // }

  // .css-12jo7m5 {
  //     font-size: 14px !important;
  //     width: 8em !important;
  //     line-height: 1.75em;
  // }

  .changeowner-div {
    width: 602px;
    height: 120px;
    text-align: center;

    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 124%;
    }

    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 132%;
      /* identical to box height, or 32px */

      text-align: center;

      /* Neutral/80 */

      color: #5a5c61;
    }
  }

  .interests-cont {
    padding: 1em;

    p {
      color: #5a5c61;
    }

    .row {
      margin-top: 1em;
    }

    .card-col {
      margin-bottom: 1em !important;
    }
  }

  .interest-btn {
    background-color: #f6f6f6 !important;
    border: 1px solid black !important;
    color: black !important;
    margin-right: 1.5em;
    box-shadow: none !important;
  }

  .selected-product {
    border-bottom: 6px blue solid;
    border-radius: 5px;
  }

  .hide-btn {
    display: none !important;
  }

  .interest-section {
    width: 1160px;
    height: 94px;
    left: 256px;
    top: 250px;
    padding: 1%;
    background: #ffffff;
    border-radius: 8px;
  }

  .interest-section2 {
    width: 1118px;
    height: 66px;
    padding: 1%;
    background: #ffffff;
    /* Neutral/20 */

    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 8px;
  }

  // .css-b62m3t-container {
  //     width: 100%;
  // }

  // .dropdown-parent.flex-container {
  //     .css-1hb7zxy-IndicatorsContainer {
  //         display: none !important;
  //     }
  // }

  // .interest-section2 {
  //     .css-1hb7zxy-IndicatorsContainer {
  //         display: none !important;
  //     }
  // }

  @media only screen and (max-width: 1200px) {
    .btn-div {
      float: left !important;
      margin-top: 1em;
      margin-left: 0 !important;
    }

    .cancel-btn {
      margin-left: 0 !important;
    }

    .industry-edit {
      float: left !important;
      margin-top: 2em !important;
      margin-left: 0 !important;
    }
  }

  .transaction-container {
    padding: 1em;

    .header {
      display: flex;
      justify-content: space-between;
    }

    .table-desc {
      padding-top: 1.5em;
    }

    .form-select {
      width: 0 !important;
    }
  }

  .image-type {
    margin-bottom: 2em;
    font-size: 16px;
    display: block;
  }

  .total-bal {
    width: 100%;
    height: 96px;
    border-radius: 16px;
    border: #e9e9e9 1px solid;
    text-align: center;
    padding-top: 11px;
    margin-bottom: 2em;
  }

  h3 {
    margin-bottom: 1.5em;
  }

  .addCredit-row {
    margin: 2em 10em 0 10em !important;
  }

  .btn-placeorder {
    width: 100%;
    height: 48px;
    background-color: #ffda99 !important;
    border: none !important;
    border-radius: 8px !important;
    margin-top: 2em;
  }

  .addcredit-col1 {
    background-color: #ffffff;
    border-radius: 24px;
    margin: 12px;
    margin-left: 42px;
    padding: 2em;
  }

  .addcredit-col2 {
    margin: 12px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 2em;
  }

  .input-credit {
    background-color: #e9e9e9 !important;
    border: none !important;
    border-radius: 8px !important;
    height: 3em;
  }

  .expdate {
    width: 100% !important;
    height: 3em;
    background-color: #e9e9e9 !important;
    color: #5a5c61;
    border: none !important;
    border-radius: 8px;
  }

  .cvv {
    width: 42% !important;
    height: 3em;
    background-color: #e9e9e9 !important;
    color: #5a5c61;
    border: none !important;
    border-radius: 8px;
  }

  .credit-heading {
    font-size: 2.5vw;
    font-weight: 5px;
  }

  .blue {
    background-color: blue !important;
    margin: 1em !important;
  }

  .white {
    background-color: #e9e9e9 !important;
    margin: 1em !important;
  }

  .table {
    background-color: #ffffff;
  }

  @media only screen and (max-width: 767px) {
    .notif-container {
      width: 600px !important;
    }

    .setting-tabs {
      ul {
        li {
          width: 50%;
          flex: 0 0 50%;

          button {
            width: 100% !important;
            text-align: center;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .left-section {
      overflow-y: hidden;
    }
  }

  .transaction-table {
    .header-data {
      width: 12rem;
      justify-content: space-around;
      align-items: center;
    }

    label {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .transaction-body {
    width: 1160px;
    height: 56px;
    left: 0px;
    top: 0px;

    background: #ffffff;
    border-radius: 8px 8px 0px 0px;
  }
}

.viewEditRow {
  height: 42px;
  align-content: center;
}

.orgImagePreview {
  height: 200px;
  width: 200px;
  background-color: rgba(228, 228, 228, 0.5);
  border-radius: var(--border-radius);
  border: 0;
  z-index: 20;
  position: relative;
  top: 25px;
  margin-bottom: 45px;
}

.selectThumbnail {
  position: absolute;
  align-self: center !important;
  bottom: 225px;
  z-index: 25;
  display: unset;
}

.customizeCards {
  min-height: 466px;
}
.render-dropzone {
  border-radius: 1.5rem;
  margin-bottom: 2rem;
  background-color: var(--neutral10-color);
  border: 2px dashed var(--neutral60-color);
}

ul.render-upload-list {
  border: 1px solid var(--neutral20-color);
  border-radius: 0.5rem;
  list-style: none;
  padding: 0;
  font-size: 0.8rem;

  li {
    padding: 0.5rem;
    border-bottom: 1px solid var(--neutral20-color);
    &:last-child {
      border-bottom: none;
    }
    &:nth-child(2n) {
      background-color: var(--neutral10-color);
    }

    div img {
      width: 14px;
    }
  }
}

.render-upload-group {
  font-size: 0.9rem;

  select.form-select,
  input {
    border-radius: 0.4rem;
    padding: 0.5rem;
    background-color: #fff;
    font-size: 0.9rem;
  }
}

.rbt-menu {
  border-radius: 0.4rem;
}

div.itemCount {
  position: absolute;
  bottom: 2em;
  left: 35%;
  color: var(--neutral10-color);
  font-weight: 700;
  font-size: 2.25em;
}

.panels-toggle {
  text-align: center;
}

.panels-toggle .row {
  width: 70%;
  margin: 0 auto;
}

.panel {
  background-color: var(--white-color) !important;
  height: 2.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.left-panel {
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}

.right-panel {
  border-radius: 0rem 0.5rem 0.5rem 0rem;
}

.background-gray {
  background-color: var(--neutral20-color) !important;
}

.selected-panel {
  background-color: var(--secondary-color) !important;
  color: var(--white-color);
}

.text-align-left {
  text-align: left;
}

.tac-modal-bottom {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: var(--white-color);
  margin: 0.25rem;
}

.css-1rhbuit-multiValue {
  background-color: white !important;
  border: 1px solid #26282f !important;
  border-radius: 8px !important;
}

.css-g1d714-ValueContainer {
  max-height: 2rem;
  overflow: auto !important;
}

.org-switch {
  input,
  button,
  .card {
    border-radius: 0.5rem;
  }

  .card-header {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .card {
    border-color: var(--black-color);
  }
}

.dropdown-menu {
  border-radius: 0.5rem;
}

.upload-btn {
  svg {
    margin-right: 0.5rem;
  }
}

.basic-table {
  // border: 1px solid var(--neutral60-color);
  // border-radius: 0.5rem;
  background-color: var(--background-color) !important;

  .basic-table-head {
    border-style: none !important;
    th {
      background-color: var(--background-color) !important;
      border-style: none !important;
      border-width: 0px;
    }

    tr {
      border-style: none !important;
    }

    .form-select {
      background-color: var(--background-color);
    }

    .basic-table-specification {
      color: var(--neutral80-color);
      font-size: 0.75rem;
      font-weight: 400;
      height: 1.5rem;
    }
  }

  tr {
    border-width: 0;
  }

  tbody {
    border-color: transparent;
    border-top: none !important;
    // border: 1px solid var(--neutral60-color) !important;
    // border-style: none !important;
  }

  .basic-table-head > tr > th > div {
    display: flex;
    align-items: center;
    line-height: 1.5rem;
  }

  #dropdown-basic-button {
    background-color: var(--background-color);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
    border-color: var(--neutral20-color);
    margin-left: 0.5rem;
    padding: 0;
    vertical-align: 0.2em;
  }

  .dropdown-toggle:empty::after {
    color: var(--black-color);
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.add-credits {
  .payment-info {
    .title-for-payment {
      p {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #5a5c61;
        line-height: 0.1em;
        margin: 10px 0 20px;
        color: #5a5c61;
      }

      p span {
        background: #fff;
        padding: 0 10px;
      }
    }

    .StripeElement {
      box-shadow: 0px 4px 16px rgba(38, 40, 47, 0.08);
    }
  }

  .total-balance {
    .transaction-container {
      margin-top: 0.75rem;
      color: var(--neutral80-color);
      width: 85%;
      height: 6rem;
      border: 1px solid var(--neutral20-color);
      border-radius: 1rem;
      padding: 1rem;
      text-align: center;
    }

    .your-purchase {
      width: 80%;
      text-align: start !important;
    }
  }
}

// do not need unless use custom buttons for google/apple pay
// .button-for-paynent {
//   display: flex;
//   .apple-pay {
//       display: flex;
//       flex-direction: row;
//       justify-content: center;
//       align-items: center;
//       padding: 16px 28px;
//       width: 100%;
//       background: #ffffff;
//       box-shadow: 0px 4px 16px rgba(38, 40, 47, 0.08);
//       height: 50px;
//       border-radius: 8px;
//       margin: 0 10px;
//       margin-bottom: 30px;
//   }
//   .google-pay {
//       display: flex;
//       flex-direction: row;
//       justify-content: center;
//       align-items: center;
//       padding: 16px 28px;
//       width: 100%;
//       background: #ffffff;
//       box-shadow: 0px 4px 16px rgba(38, 40, 47, 0.08);
//       height: 50px;
//       border-radius: 8px;
//       margin: 0 10px;
//       margin-bottom: 30px;
//   }
// }

.card-profile-thumbnail {
  width: 24px;
  border-radius: 4px;
}

.react-transform-wrapper {
  height: 100% !important;
  width: 100% !important;

  display: flex;
  justify-content: center;
  align-items: center;
}