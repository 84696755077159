
.textureMapThumbnail {
  width: 104px;
  height: 104px;
  border-radius: 8px;
  object-fit: cover;
}

.invertBtn {
  font-weight: normal;
  font-size: 14px;
  padding: 2px 10px !important;
  color: #5a5c61;
  background-color: gray;
  border: unset !important;
  
  &.active {
    border: unset !important;
  }
}