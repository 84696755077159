.root {
  // TODO: work on grid when only 1 item is on the last row
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 1rem;

  & > * {
    // width: calc(calc(100% - 8px) / 2);
    width: auto;
    min-width: 188px;
    max-width: 188px;
    flex: 1;
    
  }

}