.previewUI {
  margin: 0;
  right: 0;
  height: calc(100vh - var(--header-height));
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  left: var(--sidebar-width);
  z-index: 120;
  

  &.fullscreen {
    height: 100%;
    width: auto;
  }

  &.dark {
    background-color: #3D4145;
    border-top: 1px solid #595c61;
  }
}

.carouselContainer {
  flex: 1;
  overflow: hidden;

  :global(.carousel-item) {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
}

.closeButton {
  position: absolute;
  top: 1.875em;
  right: 2em;
  width: 40px;
  height: 40px;
  background: rgba(45, 49, 54, 0.4);
  border-radius: 8px;
  border: none;
  outline: none;
  z-index: 5;
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background: rgba(45, 49, 54, 0.8);
  }
}

.footer {
  flex: 0 0 48px;
  background: var(--black-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.32;
    padding: 0 16px;
    color: var(--white-color);
    margin: 0;
  }

  .actions {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;

    .activeImage {
      margin-right: 0;
      width: 24px;
      height: 24px;
      object-fit: none;
    }
  }

  .actionButton {
    padding: 0 12px;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--white-color);
    font-size: 12px;
    line-height: 1.3;

    &:hover {
      color: var(--neutral40-color);
    }
  }

  .actionText {
    color: var(--neutral40-color);
  }
}

.sidebar {
  position: fixed;
  left: 0;
  width: var(--sidebar-width);
  top: var(--header-height);
  bottom: 0;
  overflow-y: auto;
  background-color: var(--white-color);
  z-index: 120;

  &.fullscreen {
    top: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.dark {
    background-color: #3D4145;
    color: var(--white-color);
    border-right: 1px solid #5A5C61;
    border-top: 1px solid #5A5C61;
  }
}

.sidebarTop {
  border-bottom: 1px solid var(--neutral40-color);
  margin-bottom: 1.5em;
  width: 100%;
  display: flex;
  padding: 1em;
  align-items: center;

  .dark & {
    
    border-bottom-color: #5A5C61;
  }

  .avatar {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 8px;
    border: 2px solid var(--neutral10-color);
    align-self: flex-start;

    .dark & {
      border: none;
    }
  }

  .textContainer {
    display: flex;
    flex-direction: column;
  }

  .textContainerTitle {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    word-break: break-word;
  }

  .editedInfo {
    font-size: 13px;
  }

  .timeAgo {
    color: var(--neutral80-color);
    line-height: 1.7;

    .dark & {
      color: var(--neutral40-color);
    }
  }
}

.cardListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1em;
  gap: 0.5em;
  padding-bottom: 1em;
}
