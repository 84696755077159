.root {
  $space: 1.5rem;

  padding: 8px 16px;
  position: fixed;
  background: var(--black-color);
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.5;
  position: absolute;
  left: calc(var(--curator-sidebar-width) + $space);
  bottom: 24px;
  color: var(--white-color);
  max-width: 300px;
  /* right: 100px; */
  text-overflow: ellipsis;
  overflow: hidden;

  &.sidebarOpen {
    left: calc(var(--curator-sidebar-width) + var(--curator-open-sidebar-width) + $space);
  }

  &.filterOpen {
    left: calc(var(--curator-sidebar-width) + var(--curator-open-sidebar-width) + var(--curator-filter-width) + $space);
  }
}