.formContainer {
  background-color: var(--black-color);
  border-radius: 8px;
  // position: absolute;
  padding: 1rem;
  top: 0;
  right: 1rem;
  left: 1rem;
  bottom: 0;
  z-index: 10;;
}