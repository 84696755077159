.presetRow {
  gap: 9px;
}

.presetButton {
  color: #fff;
  background-color: var(--neutral80-color);
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  padding: 4px 0;
  flex: 1;

  &:hover {
    color: #fff;
  }
}

.input {
  border: none;
  box-shadow: none;
  border-radius: var(--curator-border-radius);
  padding: 12px;
}

.lockInput {
  gap: 10px;
}