.firstRow {
  position: relative;
}

.resetButton {
  position: absolute;
  right: -22px;
  left: auto;
  margin: 0;
  width: 26px;
  height: 26px;
  padding: 0;
  bottom: 22px;
  transform: translateX(14px);
  cursor: pointer;
}