.pagination {
  padding: 0 1rem 0 0;
  margin: auto 0;
}


.toggleInputContainer {
  position: relative;
  border: 1px solid #5A5C61;
  box-sizing: border-box;
  border-radius: 40px;
  width: 64px;
  height: 32px;
  margin: 0;

  input:global(.form-check-input) {
    background-image: none !important; 
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;

    &:checked {
      background-color: var(--secondary-color);

      & + .textBefore {
        opacity: 1;
      }

      & + .textBefore + .textAfter {
        opacity: 0;
      }

      & + .textBefore + .textAfter + .checkboxCircle {
        transform: translateX(32px);
      }
    }
  }

  .textAfter, .textBefore {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.7;
    color: var(--neutral60-color);
    position: absolute;
    width: 28px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    margin: auto 0;
    pointer-events: none;
    transition: all 0.2s ease;
  }

  .textBefore {
    color: var(--white-color);
    left: 7px;
    opacity: 0;
  }

  .textAfter {
    right: 7px;
    opacity: 1;
  }

  .checkboxCircle {
    width: 16px;
    height: 16px; 
    border-radius: 50%;
    background-color: var(--white-color);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 8px;
    z-index: 2;
    pointer-events: none;
    transition: all 0.2s ease;
  }
}