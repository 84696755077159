.root {
  * {
    font-size: 0.875rem;
  }

  :global(.dropdown-item:focus) {
    background-color: var(--black-color);
  }

  :global(.dropdown-toggle::after) {
    border: none;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    background: url(../../../../assets/images/curator/menu-arrow-down.svg) no-repeat center center;
  }
}