.formContainer {
  padding: 1rem 1rem 1.5rem;
}

.nameInpupt {
  background: var(--neutral20-color);
  border: 1px solid #5A5C61;
  color: var(--neutral80-color);
  box-sizing: border-box;
  border-radius: 8px;
  height: 32px;

}

.saveButton {
  width: 32px;
  height: 32px;
  padding: 0;
  margin-left: 16px;
  border: none;
  border-radius: 6px;
}