.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.btn {
  height: 48px;
}

.buttons {
  margin-top: auto;
}

.warning {
  font-size: 0.75rem;
  line-height: 1.3;
  color: var(--neutral40-color);
  display: flex;
  align-items: center;
  min-height: 24px;

}