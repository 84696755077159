.root {
  background: var(--neutral10-color);
  border: 2px dashed var(--neutral60-color);
  border-radius: 16px;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  color: var(--secondary-color);
}

.fileFormats {
  font-size: 0.75rem;
}

.text {
  font-size: 0.875rem;
  line-height: 1.71;
  color: var(--neutral80-color);
}

.input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}